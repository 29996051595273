type ENV = 'dev' | 'prod' | 'demo'

export const currentEnv: ENV = "dev";

const env: ENV = currentEnv;

export const Env = env

const API_DEV_URL = "https://control-fer-api.withvolkeno.com";
const API_DEMO_URL = "https://control-fer-api.withvolkeno.com";
const API_PROD_URL = "https://api-fbmetal.withvolkeno.com";

export const APP_URL_DEV = "https://control-fer-app.withvolkeno.com/";
export const APP_URL_DEMO = "https://control-fer-app.withvolkeno.com/";
export const APP_URL_PROD = "https://fbmetal.withvolkeno.com/";

function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}
export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";