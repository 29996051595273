import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../../redux/hook";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { cleannerError } from "../../../utils/Utils";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { LoginFormData } from "../../../utils/api/auth/auth.type";

function UseLoginForm() {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("L'email"),
    password: yup.string().required().label("Le mot de passe"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    reset,
  } = useForm<LoginFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserMutation();

  useEffect(() => {
    if (isSuccess) {
      console.log("data", data?.data);

      if (data) {
        dispatch(onSetUserToken({ user: data?.data, token: data?.token }));
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      setError("email", {
        message: err?.data?.message
          ? err?.data?.message
          : err?.status < 500
          ? "Email ou mot de passe incorrect"
          : `Une erreur de statut ${err?.status} est survenue.`,
      });

      setTimeout(() => {
        cleannerError(errors, clearErrors);
      }, 3000);
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {
    // console.log("data login", data);
    await loginUser(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseLoginForm;
