import React, { useState } from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { MdVerifiedUser } from "react-icons/md";
import TableSkeleton from "../../../common/TableSkeleton";
import "./Table.css";
import { CustomPagination } from "../../../common/Pagination";

function TopClientTable(props) {

  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        {/* <div className="content-img-profil-praticient-table">
            <img
              src={Profil}
              alt="Profil"
              className="img-profil-praticient-table"
            />
          </div> */}
        <p className="name-profil-table mb-0">{row?.nom}</p>
      </div>
    );
  };

  const statutFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center flex-column">
        <p style={{ color: "#08BB90", marginBottom: 0 }}>
          <MdVerifiedUser />
        </p>
        <p className="mb-0 text-statut-table">{row?.statut}</p>
      </div>
    );
  };
  const columns = [
    {
      dataField: "nom",
      text: `Clients`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { whiteSpace: "normal" };
      },
    },
    {
      dataField: "request_count",
      text: "Nombre de requêtes",
    },
    {
      dataField: "statut",
      text: "Statut",
      formatter: statutFormatter,
      headerStyle: () => {
        return { display: "flex", justifyContent: "center" };
      },
    },
  ];
  return (
    <>
      {props?.isLoading && <TableSkeleton headers={columns} />}
      {!props?.isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={props.clients.results || []}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={props.clients?.count}
              page={props.page}
              onChange={(page, perPage) => {
                props.setPage(page);
                props.setLimit(perPage);
              }}
              perPage={props.limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default TopClientTable;
