import React from "react";
import "./Login.css";
import { UseForgetPasswordForm } from "./requestForm/UseForgetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";
import { BtnSubmit } from "../common/Button";
import HeaderEmpty from "../shared/HeaderEmpty";

const ForgetPassword = () => {
   const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm();
  return (
    <div>
      <HeaderEmpty />
      <div className="container-page-login">
        <div className="auth-row row h-100">
          <div className="col-md-5 auth-col auth-left-side-col d-flex">
            <div className="auth-left-side-container"></div>
          </div>
          <div className="col-md-7 auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container">
                <h1 className="auth-form-title mb-4">Modifier mot de passe</h1>
                <div className="content-form-login-page">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Email
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email-or-username"
                            placeholder="Email"
                            aria-label="Username"
                            {...register("email")}
                          />
                          {errors?.email && (
                            <ErrorMessage message={errors?.email?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5">
                        {/* <NavLink
                          to="/reinitialiser-mot-de-passe"
                          className="btn auth-submit-btn trans-0-2"
                        >
                          Envoyer
                        </NavLink> */}
                        <BtnSubmit label="Envoyer" isLoading={isLoading} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
