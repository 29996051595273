import React from "react";
import "./HeaderAdmin.css";
import { NavLink } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "../SidebarAdmin/SidebarAdmin.css";
import Logo from "../../../../assets/appImages/logo.png";
import { MdNotificationsActive } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";
import { getAvatar } from "../../../../utils/Utils";

const HeaderAdmin: React.FC = () => {
  const { user } = useAppSelector((state) => state.user);
  var [showNotifications, setShowNotifications] = React.useState(false);

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(onlogout());
    window.location.pathname = "/";
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="content-logo-app-haeder-admin">
            <NavLink to="/" className="no-link">
              <img src={Logo} alt="Logo" className="logo-app-haeder-admin" />
            </NavLink>
          </div>

          <div className="header-others-items">
            <div className="container-info-user-connect-header">
              {/* <div className="search-form-container">
            <div className="search-form-content">
              <label>
                <FiSearch />
              </label>
              <input
                name="search"
                className="form-control search-input"
                placeholder="Rechercher"
              />
            </div>
          </div> */}
              {/* <div className="content-add-item-header">
            <MdOutlineAdd />
          </div> */}
              {/* <div className="container-menu-header-admin gap-3">
                <div
                  className="notification-item-container linkable position-relative"
                  onClick={() => onShowAndHideNotification()}
                >
                  <div className="notification-item-content">
                    <MdNotificationsActive className="notif-icon" />
                    <span className="notification-counter">1</span>
                  </div>
                </div>
              </div> */}
              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="dropdown dis-flex">
                    <button
                      className="user-acount-dashboard-header dropdown-toggle btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <img
                          src={getAvatar(user?.avatar)}
                          className="user-avatar"
                          alt="User Avatar"
                        />
                      </div>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-custom"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink
                        to="/admin/parametres"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                      >
                        {user?.nom}
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        className="dropdown-item"
                        style={{ fontSize: 16 }}
                        onClick={() => handleLogout()}
                      >
                        Déconnexion
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <ul className="header-hiddenable-notification-ul">
              <li className="header-hiddenable-notification-li">
                Il n'y a aucune notification .
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderAdmin;
