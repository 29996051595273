import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { Entite } from "../../../../utils/api/entites/entites.type";
import { useGetSonListQuery } from "../../../../utils/api/sons/sons.api";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { UseDeleteSon } from "../request/UseCrudSonsForm";

function DeleteButton({ item }: { item: Entite }) {
  const onDelete = UseDeleteSon(item?.slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}
function SonsAdminTable({ pays }: { pays: string }) {
  const { data = [], isLoading } = useGetSonListQuery();

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <Link
              to={`/admin/langues/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye />
            </Link>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/modifier-langues/${row?.slug}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteButton item={row} />
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom",
    },
    {
      dataField: "pays",
      text: `Pays`,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data.filter((f) => f.pays === pays)}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
        </>
      )}
    </>
  );
}

export default SonsAdminTable;
