import { Entite } from "../../../../utils/api/entites/entites.type";

function InfoPersoTab({ item }: { item: Entite }) {
  return (
    <>
      <div className="profile-param-tab-container">
        <div className="row">
          <div className="col-md-6 conten-info-perso-entite my-3">
            <h6 className="text-libelle-info-perso">Nom</h6>
            <p className="text-value-info-perso">{item?.nom}</p>
          </div>

          <div className="col-md-6 conten-info-perso-entite my-3">
            <h6 className="text-libelle-info-perso">Pays</h6>
            <p className="text-value-info-perso">{item?.pays}</p>
          </div>

          <div className="col-md-6 conten-info-perso-entite my-3">
            <h6 className="text-libelle-info-perso">Langues</h6>
            <p className="text-value-info-perso">{item?.langues}</p>
          </div>

          <div className="col-md-6 conten-info-perso-entite my-3">
            <h6 className="text-libelle-info-perso">Nombre d'utilisateurs</h6>
            <p className="text-value-info-perso"> {item?.user_counts} </p>
          </div>

          <div className="col-md-6 conten-info-perso-entite my-3">
            <h6 className="text-libelle-info-perso">Nombre de requêtes</h6>
            <p className="text-value-info-perso"> {item?.request_counts} </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoPersoTab;
