import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { TbTrashXFilled } from "react-icons/tb";
import { useGetListAdminUserQuery } from "../../../../utils/api/user/user.api";
import { CustomPagination } from "../../../common/Pagination";
import { IUser } from "../../../../utils/api/user/user.type";
import { UseArchiveUser } from "../request/UseCrudAdminForm";
import { useAppSelector } from "../../../../redux/hook";

function DeleteButton({ item }: { item: IUser }) {
  const onArchive = UseArchiveUser(item);
  return (
    <button
      className="btn btn-action-icon-delete with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onArchive()}
    >
      {item?.is_archive ? <TbTrashXFilled /> : <FaTrash />}
    </button>
  );
}

function ListeAdminsTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetListAdminUserQuery({ page, limit, q });

  const { user } = useAppSelector((s) => s?.user);
  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        {user?.user_type === "superadmin" && <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/modifier-administrateur/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
            >
              <MdEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteButton item={row} />
          </div>
        </div>}
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: `Nom`,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "telephone",
      text: "Numéro de téléphone",
    },
    {
      dataField: "pays",
      text: "Pays",
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  // const selectRow = {
  //   mode: "checkbox",
  // };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            // selectRow={selectRow}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPage(page);
                setLimit(perPage);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ListeAdminsTable;
