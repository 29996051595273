import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { cleannerError } from "../../../../utils/Utils";
import {
  useAddOrEditNormeMutation,
  useDeleteNormeMutation,
} from "../../../../utils/api/normes/normes.api";
import { Norme, NormeFormData } from "../../../../utils/api/normes/normes.type";
import { Color } from "../../../../utils/theme";

function UseCrudNormeForm(item?: Norme) {
  const validationSchema = yup.object().shape({
    // langues: yup.string().required().label("La langue").nullable(),
    pays: yup.string().required().label("Le pays").nullable(),
    titre: yup.string().required().label("Le titre").nullable(),
    slug: yup.string().nullable(),
    fichier: yup.mixed().when("slug", {
      is: (val: string) => !val,
      then: yup
        .mixed()
        .test("required", "Le fichier est un champs obligatoire", (file) => {
          if (file) return true;
          return false;
        }),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<NormeFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditNormeMutation();
  const navigate = useNavigate();
  const [fichier, setFichier] = useState("");

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    let file: any = e?.target?.files?.length ? e?.target?.files[0] : "";
    setValue("fichier", file || "");
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !item
          ? "Norme ajoutée avec succès!"
          : "Norme modifiée avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/admin/normes");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : err?.data?.fichier
          ? "Assurez-vous que le nom du fichier comporte au maximum 100 caractères."
          : `Une erreur de statut ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof NormeFormData)[] = ["slug", "titre", "pays"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
      if (item?.fichier) {
        setFichier(item?.fichier);
      }
    }
  }, [item]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: NormeFormData) => {
    // console.log("data", data);
    const fd = await new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await sendData({ slug: item?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeFile,
    fichier,
  };
}

export default UseCrudNormeForm;

export function UseDeleteNorme(slug: string) {
  const [deleteData] = useDeleteNormeMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette norme ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La norme a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
