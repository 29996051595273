import { configureStore } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { Env, currentEnv } from "../utils/http";

import { AnnonceApi } from "../utils/api/annonce/annonce.api";
import { DashboardApi } from "../utils/api/dashboard/dashboard.api";
import { EntiteApi } from "../utils/api/entites/entites.api";
import { NormesApi } from "../utils/api/normes/normes.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import { SontApi } from "../utils/api/sons/sons.api";
import { UserApi } from "../utils/api/user/user.api";
import { UserSlice } from "./slice/User.slice";

const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [EntiteApi.reducerPath]: EntiteApi.reducer,
    [AnnonceApi.reducerPath]: AnnonceApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [NormesApi.reducerPath]: NormesApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [SontApi.reducerPath]: SontApi.reducer,
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    EntiteApi.middleware,
    AnnonceApi.middleware,
    DashboardApi.middleware,
    NormesApi.middleware,
    ContactApi.middleware,
    SontApi.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
