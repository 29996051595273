import React from 'react'
import cover from "../../../assets/appImages/admincover.png";
import { Input } from '../../common/Input';
// import { Input } from './ParametreTabs/MonCompteAdmin';

function DetailsAdmin() {


  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin medsain-page-title-container mb-3">
        <div className="custom-form-container">
          <div className="custom-title">
            Détails de l’’administrateur
          </div>
          <div className="admin-cover-and-name-container">
              <img src={cover} alt="cover" srcSet="" />
              <span>Abdou Diop</span>
          </div>
        </div>
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-3">
        <div className="custom-form-container">
            <div className="custom-title">
                Informations personnelles
            </div>
            <form action="">
                <div className="c_grid">
                    <Input 
                        label="Prénom et Nom"
                        type="text"
                        placeholder="Abdou Diop"
                    />
                    <Input 
                        label="Adresse mail"
                        type="text"
                        placeholder="cfmpl@cfmpl.sn"
                    />
                    <Input 
                        label="Adresse"
                        type="text"
                        placeholder="Rocade Fann Bel Air B.P. 32 395 Dakar Ponty Dakar Sén..."
                    />
                    <Input 
                        label="Mot de passe"
                        type="password"
                        placeholder="***********"
                    />
                    <Input 
                        label="Numéro de téléphone"
                        type="text"
                        placeholder="+221 783047777"
                    />
                </div>
            </form>
        </div>
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-3">
        <div className="custom-form-container">
          <div className="custom-title">
          Permissions
          </div>
          <div className="">
            <div className="checkbox-add-permissions mt-4">
                <p className='content-input-checkbox-setting'>
                  <input type="checkbox" className="input-checkbox-setting-item" id="dashboard" />
                  <label htmlFor="dashboard">Dashboard</label>
                </p>
                <p className='content-input-checkbox-setting'>
                  <input type="checkbox" className="input-checkbox-setting-item" id="entite" />
                  <label htmlFor="entite">Entité</label>
                </p>
                <p className='content-input-checkbox-setting'>
                  <input type="checkbox" className="input-checkbox-setting-item" id="utilisateurs" />
                  <label htmlFor="utilisateurs">Utilisateurs</label>
                </p>
                <p className='content-input-checkbox-setting'>
                  <input type="checkbox" className="input-checkbox-setting-item" id="messages" />
                  <label htmlFor="messages">Messages</label>
                </p>
                <p className='content-input-checkbox-setting'>
                  <input type="checkbox" className="input-checkbox-setting-item" id="parametres" />
                  <label htmlFor="parametres">Paramètres</label>
                </p> 
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsAdmin