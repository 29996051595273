import React from "react";
import HistoriqueTable from "./HistoriqueTable";
import { Entite } from "../../../../utils/api/entites/entites.type";

const Historique = ({item}:{item:Entite}) => {
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin">
          <div className="page-title-container mb-3">
            <div className="admin-page-title">Historique des requêtes</div>
          </div>

          <div className="admin-table">
            <HistoriqueTable item={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historique;
