import { useSearchParams } from "react-router-dom";
import "../components/auth/Login.css";
import HeaderEmpty from "./shared/HeaderEmpty";

const config = {
  sn: {
    email: "info@mmdsteel.com",
    icon: require("../assets/mobile/sn.png"),
    name: "Fabrimétal Sénégal",
  },
  bu: {
    email: "cimmetalsa@cimmetalgroup.com",
    icon: require("../assets/mobile/burkina.png"),
    name: "CIM METAL SA",
  },
  ml: {
    email: "info@mmdsteel.com",
    icon: require("../assets/mobile/ma.png"),
    name: "Imafer SA",
  },
  be: {
    email: "info@mmdsteel.com",
    icon: require("../assets/mobile/ben.png"),
    name: "Fabrimétal Bénin",
  },
};

const PrivacyMobile = () => {
  const [params] = useSearchParams();
  const app = params.get("app");
  const data = config[app as keyof typeof config] ?? config.sn;
  return (
    <div>
      <HeaderEmpty icon={data.icon} />
      <div className="container-page-login">
        <div className="auth-row row h-100">
          <div className="col-md-5 auth-col auth-left-side-col d-flex">
            <div className="auth-left-side-container"></div>
          </div>
          <div className="col-md-7 auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container">
                <h1 className="auth-form-title mb-4">{data.name}</h1>
                <div className="content-form-login-page">
                  <p>
                    <p>
                      <strong>Politique de Confidentialité</strong>
                    </p>
                    <p>
                      <i>Date d'entrée en vigueur : 16/07/2024</i>
                    </p>
                    <p>
                      <i>Dernière mise à jour : 16/07/2024</i>
                    </p>
                    <p>
                      <strong>1. Collecte et Utilisation des Données</strong>
                    </p>
                    <p>
                      Nous nous engageons à respecter votre vie privée et à
                      protéger vos données personnelles. Lorsque vous utilisez
                      notre application de reconnaissance de conformité de fer,
                      nous pouvons collecter et utiliser certaines informations
                      pour améliorer votre expérience utilisateur. Les
                      informations que nous collectons peuvent inclure :
                    </p>
                    <ul>
                      <li>
                        Cette application collecte des données personnelles
                        telles que votre numéro téléphone pour activer la
                        fonctionnalité de reconnaissance de la langue
                        automatiquement. Nous ne partageons en aucun cas vos
                        données avec des tiers.
                      </li>
                      <li>
                        Des informations techniques telles que l'adresse IP, le
                        type de dispositif, le système d'exploitation, etc.
                      </li>
                    </ul>
                    <p>
                      Ces informations sont utilisées dans le but de fournir nos
                      services, de personnaliser votre expérience, d'analyser
                      les tendances d'utilisation et d'améliorer nos
                      fonctionnalités.
                    </p>
                    <p>
                      <strong>2. Conservation des Données</strong>
                    </p>
                    <p>
                      Nous conserverons vos données personnelles aussi longtemps
                      que nécessaire pour atteindre les finalités pour
                      lesquelles elles ont été collectées et conformément aux
                      lois en vigueur. Si vous souhaitez que vos données soient
                      supprimées de notre système, veuillez nous contacter à{" "}
                      <a href={`mailto:${data.email}`}>{data.email}</a>.
                    </p>
                    <p>
                      <strong>3. Partage d'Informations</strong>
                    </p>
                    <p>
                      Nous ne partageons pas vos informations personnelles avec
                      des tiers, sauf dans les cas suivants :
                    </p>
                    <ul>
                      <li>Avec votre consentement explicite.</li>
                      <li>
                        Lorsque cela est requis par la loi ou par une ordonnance
                        judiciaire.
                      </li>
                      <li>
                        Pour protéger nos droits, notre sécurité ou celle des
                        autres utilisateurs.
                      </li>
                      <li>
                        Dans le cadre d'une fusion, acquisition ou vente
                        d'actifs, où vos données pourraient être transférées à
                        un tiers en tant qu'actif de l'entreprise.
                      </li>
                    </ul>
                    <p>
                      <strong>4. Sécurité des Données</strong>
                    </p>
                    <p>
                      La sécurité de vos données est une priorité pour nous.
                      Nous mettons en place des mesures de sécurité techniques
                      et organisationnelles pour prévenir tout accès non
                      autorisé, toute divulgation, altération ou destruction de
                      vos données personnelles.
                    </p>
                    <p>
                      <strong>5. Vos Droits</strong>
                    </p>
                    <p>
                      Vous avez le droit de demander l'accès à vos données
                      personnelles, de les rectifier, de les supprimer ou de
                      limiter leur traitement. Vous pouvez également vous
                      opposer au traitement de vos données et exercer votre
                      droit à la portabilité des données. Pour exercer ces
                      droits, veuillez nous contacter à {data.email}.
                    </p>
                    <p>
                      <strong>
                        6. Modifications de la Politique de Confidentialité
                      </strong>
                    </p>
                    <p>
                      Nous nous réservons le droit de modifier cette politique
                      de confidentialité à tout moment. Les modifications
                      prendront effet dès leur publication sur notre
                      application. Nous vous encourageons à consulter
                      régulièrement notre politique de confidentialité pour être
                      informé des mesures de protection de vos données.
                    </p>
                    <p>
                      Si vous avez des questions concernant notre politique de
                      confidentialité, n'hésitez pas à nous contacter à{" "}
                      <a href={`mailto:${data.email}`}>{data.email}</a>.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyMobile;
