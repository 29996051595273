export const regions = [
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Diourbel",
    value: "Diourbel",
  },
  {
    label: "Fatick",
    value: "Fatick",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack",
  },
  {
    label: "kédougou",
    value: "kédougou",
  },
  {
    label: "Kolda",
    value: "Kolda",
  },
  {
    label: "Louga",
    value: "Louga",
  },
  {
    label: "Matam",
    value: "Matam",
  },
  {
    label: "Saint-Louis",
    value: "Saint-Louis",
  },
  {
    label: "Sédhiou",
    value: "Sédhiou",
  },
  {
    label: "Tambacounda",
    value: "Tambacounda",
  },
  {
    label: "Thiès",
    value: "Thiès",
  },
  {
    label: "Ziguinchor",
    value: "Ziguinchor",
  },
];

export const selectOptionsPays = [
  {
    label: "Bénin",
    value: "Bénin",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    label: "Mali",
    value: "Mali",
  },
  {
    label: "Sénégal",
    value: "Sénégal",
  },
];

type Authorization = {
	field: FieldType;
	label: string;
	path: string;
};

export type FieldType =
	| "dashboard"
	| "entites"
	| "utilisateurs"
	| "annonces"
	| "parametres"
	| "normes"
export const Authorisations: Authorization[] = [
	{
		field: "dashboard",
		label: "Dashboard",
		path: "/admin/tableau-de-bord",
	},
	{
		field: "entites",
		label: "Entites",
		path: "/admin/entites",
	},
	{
		field: "normes",
		label: "Normes",
		path: "/admin/normes",
	},
	{
		field: "utilisateurs",
		label: "Utilisateurs",
		path: "/admin/utilisateurs",
	},
	{
		field: "annonces",
		label: "Annonces",
		path: "/admin/annonces",
	},
	{
		field: "parametres",
		label: "Parametres",
		path: "/admin/parametres",
	},
];