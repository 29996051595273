import { Son } from "../../../../utils/api/sons/sont.type";
import { AudioItem } from "../../../common/MyDropzone";

function InfoPersoTab({ item }: { item: Son }) {
  const DATA = [
    { field: "longeur_du_fer", label: "Longeur du fer" },
    { field: "poids_du_fer", label: "Poids du fer" },
    { field: "diametre_du_fer", label: "Diamètre du fer" },
    { field: "fer_valide", label: "Votre fer est conforme" },
    { field: "fer_non_valide", label: "Votre fer n'est pas conforme" },
    { field: "choisir_une_langue", label: "Choisissez une langue" },
  ];
  return (
    <>
      <div className="profile-param-tab-container">
        <div className="row">
          <div className="col-md-6 conten-info-perso-entite my-3">
            <h6 className="text-libelle-info-perso">Nom</h6>
            <p className="text-value-info-perso">{item?.nom}</p>
          </div>

          <div className="col-md-6 conten-info-perso-entite my-3">
            <h6 className="text-libelle-info-perso">Pays</h6>
            <p className="text-value-info-perso">{item?.pays}</p>
          </div>
          {DATA.map((c) => {
            const audio = item ? item[c.field] : undefined;
            return (
              <div
                key={c.field}
                className="col-md-6 conten-info-perso-entite my-3"
              >
                <p className="text-value-info-perso"> {c.label} </p>
                {!!audio && <AudioItem audio={audio} />}
                {!audio && (
                  <div className="alert alert-danger">
                    Pas d'audio disponible
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default InfoPersoTab;
