import React, {  } from "react";
import ReactPaginate from "react-paginate";
const PER_PAGE = 24;
type PaginationProps = {
  page: number;
  onPageChange: (nextPage: number) => any;
  total: number;
  perPage?: number;
};

function Pagination({
  page,
  onPageChange,
  total,
  perPage = PER_PAGE,
}: PaginationProps) {
  return (
    /* @ts-ignore */
    <ReactPaginate
      breakLabel="..."
      initialPage={page - 1}
      nextLabel=">"
      onPageChange={(page) => onPageChange(page?.selected + 1)}
      pageRangeDisplayed={5}
      pageCount={total}
      previousLabel="<"
      pageClassName="page-item m-1"
      pageLinkClassName="page-link custom-page-link"
      previousClassName="page-item"
      previousLinkClassName="d-none"
      nextClassName="page-item"
      nextLinkClassName="d-none"
      breakClassName="page-item m-1"
      breakLinkClassName="page-link custom-break-link"
      containerClassName="pagination"
      activeClassName="active"
    />
  );
}

export default Pagination;

type CustomPaginationProps = {
  nbPages: number | any;
  page: number;
  onChange: (page: number, perPage: number) => any;
  perPage: number;
};
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null;
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        {nbPages > 0 && (
          <select
            className="form-control select-perpage"
            name="limit"
            id="limit"
            value={perPage}
            onChange={(e) => {
              onChange(1, parseInt(e.target.value, 10));
            }}
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        )}
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={Math.ceil((nbPages || 0) / perPage) || 0}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  );
}

export function PaginationCustom({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null;
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={Math.ceil((nbPages || 0) / perPage) || 0}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  );
}
