import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl, useLocationState } from "../../Utils";
import { Entite, EntiteFormData } from "./entites.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const EntiteApi = createApi({
  reducerPath: "entiteApi",
  tagTypes: ["entite", "EntiteList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getEntiteList: builder.query<PaginationResults<Entite>, TypeQuery>({
      providesTags: ["EntiteList"],
      query: (query) => QueryUrl("entites/", query),
    }),

    addOrEditEntite: builder.mutation<
      Entite,
      { slug?: string; data: EntiteFormData | FormData }
    >({
      invalidatesTags: ["EntiteList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `entites/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `entites/`,
          method: "POST",
          body: data,
        };
      },
    }),
    entiteById: builder.query<Entite | any, string>({
      query: (id) => `entites/${id}/`,
      providesTags: ["entite"],
    }),
    entiteHistorique: builder.query<PaginationResults<Entite>, string>({
      query: (slug) => `entite/${slug}/historiques/`,
      providesTags: ["entite"],
    }),
    deleteEntite: builder.mutation<Entite, string>({
      query: (slug) => ({
        url: `entites/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["EntiteList"],
    }),
  }),
});

export const {
  useGetEntiteListQuery,
  useAddOrEditEntiteMutation,
  useDeleteEntiteMutation,
  useLazyEntiteByIdQuery,
  useEntiteHistoriqueQuery
} = EntiteApi;

export function useEntiteFromLocation(): [
  Entite,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Entite | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyEntiteByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Entite, isLoading, slug as string, findById];
}
