import { useSonFromLocation } from "../../../utils/api/sons/sons.api";
import { selectOptionsPays } from "../../../utils/constante";
import { Input } from "../../common/Input";
import { AudioItem } from "../../common/MyDropzone";
import { SelectInput } from "../../common/SelectInput";
import BreadCrumb from "../../shared/BreadCrumb";
import UseCrudEntiteForm from "./request/UseCrudSonsForm";

function AddOrUpdateSonAdmin() {
  const [item] = useSonFromLocation();
  const { register, errors, onSubmit, isLoading, watch } =
    UseCrudEntiteForm(item);

  const [
    diametre_du_fer,
    longeur_du_fer,
    poids_du_fer,
    entrez_num_telephone,
    fer_valide,
    fer_non_valide,
    choisir_une_langue,
  ] = watch([
    "diametre_du_fer",
    "longeur_du_fer",
    "poids_du_fer",
    "entrez_num_telephone",
    "fer_valide",
    "fer_non_valide",
    "choisir_une_langue",
  ]);

  console.log("longeur_du_fer", longeur_du_fer);
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-2">
          <BreadCrumb pageName="Langue" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title">Ajouter une langue</div>
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    placeholder="Ajouter un nom"
                    {...register("nom")}
                    error={errors?.nom?.message}
                  />
                </div>
                <div className="col-md-6">
                  <SelectInput
                    label="Pays"
                    options={selectOptionsPays}
                    {...register("pays")}
                    error={errors?.pays?.message}
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Entrez votre numéro de téléphone"
                    accept="audio/*"
                    id="entrez_num_telephone"
                    placeholder="Entrez votre numéro de téléphone"
                    {...register("entrez_num_telephone")}
                    error={errors?.entrez_num_telephone?.message}
                  />
                  <AudioItem
                    file={entrez_num_telephone}
                    audio={item?.entrez_num_telephone}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Choisissez une langue"
                    accept="audio/*"
                    id="choisir_une_langue"
                    placeholder="Choisissez une langue"
                    {...register("choisir_une_langue")}
                    error={errors?.choisir_une_langue?.message}
                  />
                  <AudioItem
                    file={choisir_une_langue}
                    audio={item?.choisir_une_langue}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Longeur du fer"
                    accept="audio/*"
                    id="longeur_du_fer"
                    placeholder="Longeur du fer"
                    {...register("longeur_du_fer")}
                    error={errors?.longeur_du_fer?.message}
                  />
                  <AudioItem
                    file={longeur_du_fer}
                    audio={item?.longeur_du_fer}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Poids du fer"
                    accept="audio/*"
                    id="poids_du_fer"
                    placeholder="Poids du fer"
                    {...register("poids_du_fer")}
                    error={errors?.poids_du_fer?.message}
                  />
                  <AudioItem file={poids_du_fer} audio={item?.poids_du_fer} />
                </div>

                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Diamètre du fer"
                    accept="audio/*"
                    id="diametre_du_fer"
                    placeholder="Diamètre du fer"
                    {...register("diametre_du_fer")}
                    error={errors?.diametre_du_fer?.message}
                  />
                  <AudioItem
                    file={diametre_du_fer}
                    audio={item?.diametre_du_fer}
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Votre fer est conforme"
                    accept="audio/*"
                    id="fer_valide"
                    placeholder="Votre fer est conforme"
                    {...register("fer_valide")}
                    error={errors?.fer_valide?.message}
                  />
                  <AudioItem file={fer_valide} audio={item?.fer_valide} />
                </div>

                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Votre fer n'est pas conforme"
                    accept="audio/*"
                    id="fer_non_valide"
                    placeholder="Votre fer n'est pas conforme"
                    {...register("fer_non_valide")}
                    error={errors?.fer_non_valide?.message}
                  />
                  <AudioItem
                    file={fer_non_valide}
                    audio={item?.fer_non_valide}
                  />
                </div>

                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn auth-submit-btn trans-0-2"
                    disabled={isLoading}
                  >
                    {!isLoading && (item ? "Modifier" : "Ajouter")}
                    {!!isLoading && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>En cours...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateSonAdmin;
