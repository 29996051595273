import React from "react";
import ContactItem from "../ContactItem";

function MessagesContactAdmin() {
  //   const [query, setQuery] = useState("");
  return (
    <>
      {/* <div className="custom-filtered-container d-flex align-items-center mb-3 gap-3">
        <span>Filter</span>
        <input
          type="search"
          name="search_admin"
          id="search_admin"
          placeholder="Entrer un nom"
            onChange={(e) => setQuery(e?.target?.value)}
        />
      </div> */}
      <ContactItem
      //    q={query}
      />
    </>
  );
}

export default MessagesContactAdmin;
