import { useStep } from "react-hooks-helper";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useStepType } from "../../../utils/type";
import "./EntitesAdmin.css";
import SonsAdminTable from "./SonsAdminTable/SonsAdminTable";

function Langues({ pays }: { pays: string }) {
  return (
    <div className="admin-table">
      <SonsAdminTable pays={pays} />
    </div>
  );
}

const PAYS = ["Sénégal", "Bénin", "Burkina Faso", "Mali"];

const steps = PAYS.map((pays) => ({ id: pays, Component: Langues }));

const tabs = PAYS.map((pays) => ({
  key: pays,
  text: pays,
}));

function SonsAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });

  const { Component } = step;

  const props = {
    pays: PAYS[index],
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin">
          <div className="page-title-container mb-3">
            <div className="admin-page-title">Langues</div>
            <div className="container-display-title-theme">
              <NavLink
                to="/admin/ajouter-langues"
                className="btn btn-theme-body-page"
              >
                <span className="pe-2">
                  <AiOutlinePlusCircle />
                </span>
                <span>Ajouter une langue</span>
              </NavLink>
            </div>
          </div>
          <div className="medsain-dash-tabs mb-4">
            <ul className="tabs-container tabs-container-admin custom-small-menu-item mb-0 pt-4 pb-2 px-4">
              {tabs.map((tab: any, key: any) => (
                <li
                  className={`tab-item  ${
                    index === key && "tab-active-item-admin"
                  }`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SonsAdmin;
