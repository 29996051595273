import React, { useEffect, useState } from "react";
import { MessageContact } from "../../../../utils/api/contact/contact.type";
import { BtnSubmit } from "../../../common/Button";
import { useAppSelector } from "../../../../redux/hook";
import { useSendResponseContactMutation } from "../../../../utils/api/contact/contact.api";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { formatDateHour, getAvatar, getName } from "../../../../utils/Utils";
import Modal from "react-bootstrap/Modal";
import Editor from "../../../common/Editor";

function DetailsMessageModal({
  item,
  show,
  handleClose,
}: {
  item: MessageContact;
  show: boolean;
  handleClose: () => void;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useSendResponseContactMutation();
  const [value, setValue] = useState<string>("");
  useEffect(() => {
    // window.scroll(0, 10);
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Message envoyé avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        setValue("");
      });
    }
    const err = error as any;

    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const onSubmitResponse = async (e) => {
    e?.preventDefault();
    var plaintext = value?.replace(/(<([^>]+)>)/gi, "");
    if (plaintext && plaintext?.length) {
      // console.log("plaintext", plaintext);
      await sendData({ message: item?.id, response: value, user: user?.id });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (value.trim().length > 0) {
            onSubmitResponse(e);
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="message-item-title">
            Réponse à (
            {!!item?.user
              ? getName(item?.user)
              : !!item?.name
              ? item?.name
              : !!item?.email
              ? item?.email
              : item?.phone}
            )
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-container px-3">
            <div className="row border-bottom pb-2">
              <div className="col-md-12 form-group">
                <div className="admin-content-message py-2">{item.message}</div>
              </div>
            </div>

            <div className="row mb-4 mt-4">
              {item.responses?.map((r, index) => {
                return (
                  <div className="border-bottom my-3" key={index}>
                    <div className="col-12 mb-3">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          className="avatar-auteur-response-card mx-1"
                          src={getAvatar(r?.user?.avatar)}
                          alt="Avatar"
                          width={"50"}
                          height={"50"}
                        />
                        <p className="text-response-card fs-12 fw-300 sender-name">
                          Par&nbsp;
                          <strong>{r?.user?.nom}</strong>
                          <br />
                          <span className="cm-sender-email">
                            {formatDateHour(r?.createdAt)}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12 itm-center mx-1">
                      <p
                        className="text-response-card fs-15 fw-300"
                        dangerouslySetInnerHTML={{
                          __html: r?.response,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="col-md-12 mb-3">
                <div className="mb-4 form-group">
                  <label className="form-label label-form-admin">Réponse</label>
                  {/* <ReactQuill value={value} onChange={setValue} /> */}
                  <Editor
                    className="editor-react-quill annonce-quill"
                    value={value}
                    onChange={setValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center gap-3">
            <BtnSubmit label="Envoyer" isLoading={isLoading} />
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default DetailsMessageModal;
