import React from "react";
import { useNormeFromLocation } from "../../../utils/api/normes/normes.api";
import BreadCrumb from "../../shared/BreadCrumb";
import { ApiBaseUrl } from "../../../utils/http";

function DetailsNormesAdmin() {
  const [item] = useNormeFromLocation();
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-2">
          <BreadCrumb pageName="Normes" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title mb-3">{item?.titre}</div>
          <div className="form-add-container my-3">
            <iframe
              src={ApiBaseUrl + item?.fichier}
              frameBorder="0"
              width={`100%`}
              height={600}
              allowFullScreen
              seamless
              title="doc"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsNormesAdmin;
