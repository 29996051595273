import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import  './EntitesAdmin.css'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import EntitesAdminTable from './EntitesAdminTable/EntitesAdminTable'


function EntitesAdmin() {
  const [query, setQuery] = useState("")
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin">
          <div className="page-title-container mb-3">
            <div className="admin-page-title">Entités</div>
            <div className="container-display-title-theme">
              <NavLink
                to="/admin/ajouter-entite"
                className="btn btn-theme-body-page"
              >
                <span className="pe-2">
                  <AiOutlinePlusCircle />
                </span>
                <span>Ajouter une entité</span>
              </NavLink>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="content-form-filter mt-3 mb-4">
                <form>
                  <div className="content-form-search-bloc">
                    <input
                      type="search"
                      className="form-control form-control-search"
                      placeholder="Rechercher"
                      id="entiteSearch"
                      onChange={(e) => setQuery(e?.target?.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="admin-table">
            <EntitesAdminTable q={query} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntitesAdmin