import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationState } from "../../Utils";
import { ApiBaseUrl } from "../../http";
import { Son, SonFormData } from "../sons/sont.type";
import { prepareHeaders } from "../user/user.api";

export const SontApi = createApi({
  reducerPath: "SontApi",
  tagTypes: ["son", "sonlist"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getSonList: builder.query<Son[], void>({
      providesTags: ["sonlist"],
      query: () => "visiteur/sons/",
    }),

    addOrEditSon: builder.mutation<
      Son,
      { slug?: string; data: SonFormData | FormData }
    >({
      invalidatesTags: ["sonlist"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `son/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `sons/`,
          method: "POST",
          body: data,
        };
      },
    }),
    sonById: builder.query<Son, string>({
      query: (id) => `son/${id}/`,
      providesTags: ["son"],
    }),
    deleteSon: builder.mutation<Son, string>({
      query: (slug) => ({
        url: `son/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["sonlist"],
    }),
  }),
});

export const {
  useGetSonListQuery,
  useAddOrEditSonMutation,
  useDeleteSonMutation,
  useLazySonByIdQuery,
} = SontApi;

export function useSonFromLocation(): [
  Son,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Son | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazySonByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Son, isLoading, slug as string, findById];
}
