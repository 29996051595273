import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import {
  useAddOrEditAnnonceMutation,
  useDeleteAnnonceMutation,
} from "../../../../utils/api/annonce/annonce.api";
import {
  Annonce,
  AnnonceFormData,
} from "../../../../utils/api/annonce/annonce.type";
import { Color } from "../../../../utils/theme";

function UseCrudAnnonceForm(modalId: string, item?: Annonce) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Le titre").nullable(),
    message: yup
      .string()
      .required()
      .label("Le message")
      .nullable()
      .transform((val) => (val === "<p><br></p>" ? "" : val)),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<AnnonceFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditAnnonceMutation();
  const [message, setMessage] = useState("");

  const handleChange = (val: string) => {
    setMessage(val);
    setValue("message", val);
  };

  const handleReset = () => {
    if (!item) {
      setMessage("");
      reset();
    }
    closeModal(modalId);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !item
          ? "Annonce ajoutée avec succès!"
          : "Annonce modifiée avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof AnnonceFormData)[] = ["slug", "message", "titre"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
      if (item?.message) {
        setMessage(item?.message);
      }
    }
  }, [item]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: AnnonceFormData) => {
    console.log("data", data);

    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChange,
    message,
    handleReset,
  };
}

export default UseCrudAnnonceForm;
export function UseDeleteAnnonce(slug: string) {
  const [deleteData] = useDeleteAnnonceMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette annonce ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'annonce a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
