import { NavLink } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";

function HeaderEmpty({ icon }: { icon?: string }) {
  return (
    <div className="empty-header-auth">
      <div className="content-img-logo-login-app">
        <NavLink to={`/`}>
          <img
            style={icon ? { width: 150, height: "auto" } : {}}
            src={icon ?? Logo}
            alt="Logo"
            className="img-logo-login-app"
          />
        </NavLink>
      </div>
    </div>
  );
}

export default HeaderEmpty;
