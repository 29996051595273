import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { cleannerError } from "../../../../utils/Utils";
import {
  useAddOrEditAdminMutation,
  useDeleteUserMutation,
} from "../../../../utils/api/user/user.api";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { Authorisations } from "../../../../utils/constante";
import { Color } from "../../../../utils/theme";

function UseCrudAdminForm(user?: IUser) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom").nullable(),
    email: yup.string().email().required().label("Email").nullable(),
    user_type: yup.string().required().label("Type").nullable(),
    telephone: yup.string().required().label("Le N° de téléphone").nullable(),
    adresse: yup.string().label("L'adresse").nullable(),
    pays: yup.string().label("Pays").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<UserFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditAdminMutation();

  const handleReset = () => {
    if (!user) {
      reset();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !user
          ? "Administrateur ajouté avec succès!"
          : "Administrateur modifié avec succès!",
        text: !user
          ? "Les identifiants ont été envoyés par mail pour lui permettre de se connecter sur la plateforme."
          : "",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleReset();
        navigate("/admin/parametres", { state: "liste-administrateurs" });
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  const [show, setShow] = useState(false);

  const handleAdminChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "admin") {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  useEffect(() => {
    if (user?.id) {
      // setValue("hasEdit", true);
      const fields: (keyof UserFormData)[] = [
        "slug",
        "email",
        "adresse",
        "telephone",
        "nom",
        "pays",
        "user_type",
        "dashboard",
        "entites",
        "utilisateurs",
        "annonces",
        "parametres",
        "normes",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
        if (field === "user_type") {
          setValue(field, user[field]);
        }
      }
      if (user?.user_type === "admin") {
        setShow(true);
      } else {
        setShow(false);
      }
    } else {
      Authorisations.forEach((item) => {
        setValue(item?.field, true);
      });
    }
  }, [user]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: UserFormData) => {
    console.log("data", data);
    if (data?.email) {
      data["email"] = (data?.email).toLowerCase();
    }

    sendData({ slug: data?.slug, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    show,
    handleAdminChange,
  };
}

export default UseCrudAdminForm;
export function UseArchiveUser(item: IUser) {
  const [sendData] = useAddOrEditAdminMutation();
  let data = {
    is_active: !item?.is_archive ? false : true,
    is_archive: !item?.is_archive,
  };
  const onArchive = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_archive ? "désarchiver" : "archiver"
      } cet administrateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({ slug: item?.slug, data: data });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'administrateur a été ${
              item?.is_archive ? "désarchivé" : "archivé"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onArchive;
}

export function UseDeleteUser(slug: string) {
  const [deleteData] = useDeleteUserMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet utilisateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'utilisateur a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
