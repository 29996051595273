import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/Pagination";
import TableSkeleton from "../../../common/TableSkeleton";
import { useEntiteHistoriqueQuery } from "../../../../utils/api/entites/entites.api";
import { Entite } from "../../../../utils/api/entites/entites.type";
import moment from "moment";
import { getAvatar } from "../../../../utils/Utils";
import Verifie from "../../../../assets/icons/verifie.svg"

function HistoriqueTable({item}:{item:Entite}) {

  const { data = { results: [], count: 0 }, isLoading } = useEntiteHistoriqueQuery(item?.slug);
	// console.log("user data", data)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-itemt-table">
          <img
            src={getAvatar(row?.photo)}
            alt="Profil"
            className="img-avatar-itemt-table"
          />
        </div>
        <p className="name-profil-patient-table mb-0">{!!cell ? cell : "Non renseigné"}</p>
      </div>
    );
  };
  const statutFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-itemt-table">
          <img
            src={Verifie}
            alt="Profil"
            className="img-profil-itemt-table"
          />
        </div>
      </div>
    );
  };
  const dateFormatter = (cell: any, row: any) => {
    return (
      <div>{moment(cell).format("L")}</div>
    );
  };
  const columns = [
    {
      dataField: "nom",
      text: `Client`,
      formatter: nameFormatter
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: dateFormatter,
    },
    {
      dataField: "statut",
      text: "Status",
      formatter: statutFormatter,
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPage(page);
                setLimit(perPage);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default HistoriqueTable;
