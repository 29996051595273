import { ApiBaseUrl } from "../../utils/http";

export const FileItem = ({ file }: {file: any}) => {
    return (
      <div className="product-img-card">
        <iframe
        src={ApiBaseUrl + file}
        frameBorder="0"
        width={`100%`}
        height={500}
        allowFullScreen
        seamless
        title="doc"
        ></iframe>
      </div>
    );
  };