import { useEntiteFromLocation } from "../../../utils/api/entites/entites.api";
import { selectOptionsPays } from "../../../utils/constante";
import { Input } from "../../common/Input";
import { ImageItem } from "../../common/MyDropzone";
import { SelectInput } from "../../common/SelectInput";
import BreadCrumb from "../../shared/BreadCrumb";
import UseCrudEntiteForm from "./request/UseCrudEntiteForm";

function AddOrUpdateEntiteAdmin() {
  const [item] = useEntiteFromLocation();
  const {
    register,
    errors,
    onSubmit,
    logo,
    handleChangeFile,
    isLoading,
    watch,
  } = UseCrudEntiteForm(item);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-2">
          <BreadCrumb pageName="Entités" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title">Ajouter une entité</div>
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Nom de l’entité"
                    id="nom"
                    placeholder="Ajouter un titre"
                    {...register("nom")}
                    error={errors?.nom?.message}
                  />
                </div>
                <div className="col-md-6">
                  <SelectInput
                    label="Pays"
                    options={selectOptionsPays}
                    {...register("pays")}
                    error={errors?.pays?.message}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Langue"
                    id="langues"
                    placeholder="Ajouter une langue"
                    {...register("langues")}
                    error={errors?.langues?.message}
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Logo"
                    accept="image/*"
                    id="logo"
                    placeholder="Ajouter une image"
                    onChange={(e) => handleChangeFile(e)}
                    error={errors?.logo?.message}
                  />
                  {!!logo && <ImageItem file={logo} />}
                </div>
                {/* <div className="col-md-12">
                  <MyDropzone setImages={setImage} />
                </div> */}
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn auth-submit-btn trans-0-2"
                    disabled={isLoading}
                  >
                    {!isLoading && (item ? "Modifier" : "Ajouter")}
                    {!!isLoading && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>En cours...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateEntiteAdmin;
