import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl, useLocationState } from "../../Utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Annonce, AnnonceFormData } from "./annonce.type";

export const AnnonceApi = createApi({
  reducerPath: "annonceApi",
  tagTypes: ["annonce", "annonceList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getAnnonceList: builder.query<PaginationResults<Annonce>, TypeQuery>({
      providesTags: ["annonceList"],
      query: (query) => QueryUrl("annonces/", query),
    }),

    addOrEditAnnonce: builder.mutation<Annonce, AnnonceFormData>({
      invalidatesTags: ["annonceList"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `annonces/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `annonces/`,
          method: "POST",
          body: data,
        };
      },
    }),
    AnnonceById: builder.query<Annonce | any, string>({
      query: (slug) => `annonces/${slug}/`,
      providesTags: ["annonce"],
    }),
    deleteAnnonce: builder.mutation<Annonce, string>({
      query: (slug) => ({
        url: `annonces/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["annonceList"],
    }),
  }),
});

export const {
  useAddOrEditAnnonceMutation,
  useGetAnnonceListQuery,
  useLazyAnnonceByIdQuery,
  useDeleteAnnonceMutation,
} = AnnonceApi;
