/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { cleannerError } from "../../../../utils/Utils";
import { useAddOrEditUserMutation } from "../../../../utils/api/user/user.api";
import { UserFormData } from "../../../../utils/api/user/user.type";
import { Color } from "../../../../utils/theme";

function UseEditUserForm() {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom").nullable(),
    email: yup.string().email().required().label("L'email").nullable(),
    telephone: yup.string().required().label("Le N° de téléphone").nullable(),
    adresse: yup.string().label("L'adresse").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();

  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditUserMutation();
  let user = useAppSelector((s) => s?.user?.user);
  useEffect(() => {
    if (isSuccess) {
      dispatch(onSetUser(data));
      Swal.fire({
        icon: "success",
        title: "Informations modifiées avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (user?.id) {
      const fields: (keyof UserFormData)[] = [
        "nom",
        "telephone",
        "adresse",
        "email",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
    }
  }, [user]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: UserFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (data[key] !== "null") {
        fd.append(key, data[key]);
      }
    }

    sendData({ slug: user?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default UseEditUserForm;
