import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { Input } from "../../common/Input";
import { SelectInput } from "../../common/SelectInput";
import { selectOptionsPays } from "../../../utils/constante";
import UseCrudNormeForm from "./request/UseCrudNormeForm";
import { useNormeFromLocation } from "../../../utils/api/normes/normes.api";
import { FileItem } from "../../common/FileItem";

function AddOrUpdateNormeAdmin() {
  const [item] = useNormeFromLocation();
  const { register, errors, onSubmit, handleChangeFile, isLoading, fichier } =
    UseCrudNormeForm(item);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-2">
          <BreadCrumb pageName="Normes" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title">Ajouter une norme</div>
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Titre"
                    id="titre"
                    placeholder="Ajouter un titre"
                    {...register("titre")}
                    error={errors?.titre?.message}
                  />
                </div>
                <div className="col-md-6">
                  <SelectInput
                    label="Pays"
                    options={selectOptionsPays}
                    {...register("pays")}
                    error={errors?.pays?.message}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="file"
                    label="Fichier"
                    accept="application/pdf"
                    id="fichier"
                    placeholder="Ajouter un fichier"
                    onChange={(e) => handleChangeFile(e)}
                    error={errors?.fichier?.message}
                  />
                  {!!fichier && <FileItem file={fichier} />}
                </div>
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn auth-submit-btn trans-0-2"
                    disabled={isLoading}
                  >
                    {!isLoading && (item ? "Modifier" : "Ajouter")}
                    {!!isLoading && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>En cours...</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateNormeAdmin;
