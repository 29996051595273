import React from "react";
import { NavLink } from "react-router-dom";
import { FaUser, FaUsers } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { MdOutlineWork } from "react-icons/md";
import { AiOutlineMenuUnfold } from "react-icons/ai";

const SidebarMobile = () => {
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/tableau-de-bord"
                >
                  <BiCategory />
                  <span className="item-nav-menu drop-header">Mon tableau de bord</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/entites"
                >
                  <MdOutlineWork />
                  <span className="item-nav-menu drop-header">Entités</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/utilisateurs"
                >
                  <FaUsers />
                  <span className="item-nav-menu drop-header">Utilisateurs</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/annonces"
                >
                  <BsFillChatDotsFill />
                  <span className="item-nav-menu drop-header">Annonces</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/parametres"
                >
                  <FaUser />
                  <span className="item-nav-menu drop-header">Paramètres</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
