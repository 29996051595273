import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../Utils";
import { MessageContact, MessageContactFormData, ResponseContact, ResponseContactFormData } from "./contact.type";

export const ContactApi = createApi({
    reducerPath: "contactApi",
    tagTypes: ["contact", "ContactList"],
    baseQuery: fetchBaseQuery({
      baseUrl: `${ApiBaseUrl}/api/`,
      prepareHeaders,
    }),
    endpoints: (builder) => ({
      getContactList: builder.query<PaginationResults<MessageContact>, TypeQuery>({
        providesTags: ["ContactList"],
        query: (query) => QueryUrl("contacts/", query),
      }),
      // getContactBySlug: builder.query<MessageContact | any, string>({
      //   query: (slug) => `contact/${slug}/`,
      //   providesTags: ["contactBySlug"],
      // }),
      addOrUpdateContact: builder.mutation<
        MessageContact,
        { slug?: string; data: MessageContact | FormData }
      >({
        query: ({ slug, data }) => ({
          url: slug ? `contact/${slug}/` : `contact/`,
          method: slug ? "PUT" : "POST",
          body: data,
        }),
        invalidatesTags: ["contact"],
      }),
      updateContact: builder.mutation<MessageContact, MessageContactFormData>({
        invalidatesTags: ["contact", "ContactList"],
        query: ({ slug, ...data }) => {
          return {
            url: `contacts/${slug}/`,
            method: "PUT",
            body: data,
          };
        },
      }),
      deleteContact: builder.mutation<MessageContactFormData, string>({
        query: (slug) => ({
          url: `contact/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["contact"],
      }),
      sendResponseContact: builder.mutation<
        ResponseContact,
        ResponseContactFormData
      >({
        query: (data) => ({
          url: "contact/responses/",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["contact", "ContactList"],
      }),
    }),
  });
  
  export const {
    useGetContactListQuery,
    useAddOrUpdateContactMutation,
    useDeleteContactMutation,
    useSendResponseContactMutation,
    useUpdateContactMutation
  } = ContactApi;