import React, { useState } from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink, Link } from "react-router-dom";
import { useGetEntiteListQuery } from "../../../../utils/api/entites/entites.api";
import { CustomPagination } from "../../../common/Pagination";
import { Entite } from "../../../../utils/api/entites/entites.type";
import { getImage } from "../../../../utils/Utils";
import { UseDeleteEntite } from "../request/UseCrudEntiteForm";

function DeleteButton({ item }: { item: Entite }) {
  const onDelete = UseDeleteEntite(item?.slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}
function EntitesAdminTable({q}:{q:string}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetEntiteListQuery(
    {
      page,
      limit,
      q
    }
  );

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <Link
              to={`/admin/entite/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              state={{ entite: row }}
            >
              <AiFillEye />
            </Link>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/modifier-entite/${row?.slug}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteButton item={row} />
          </div>
        </div>
      </>
    );
  };

  const countryFormatter = (cell: string, row: Entite) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-praticient-table">
          <img
            src={getImage(row?.logo)}
            alt={row?.slug}
            className="img-profil-praticient-table"
          />
        </div>
        <p className="name-profil-table mb-0">{cell}</p>
      </div>
    );
  };
  const columns = [
    {
      dataField: "pays",
      text: `Pays`,
      formatter: countryFormatter,
    },
    {
      dataField: "nom",
      text: "Nom",
    },
    {
      dataField: "langues",
      text: "Langue",
    },
    {
      dataField: "user_counts",
      text: "Utilisateurs",
      headerStyle: () => {
        return { display: "flex", justifyContent: "flex-start" };
      },
    },
    {
      dataField: "request_counts",
      text: "Requête",
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPage(page);
                setLimit(perPage);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EntitesAdminTable;
