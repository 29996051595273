import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { cleannerError, getFile } from "../../../../utils/Utils";
import {
  useAddOrEditEntiteMutation,
  useDeleteEntiteMutation,
} from "../../../../utils/api/entites/entites.api";
import {
  Entite,
  EntiteFormData,
} from "../../../../utils/api/entites/entites.type";
import { Color } from "../../../../utils/theme";

function UseCrudEntiteForm(item?: Entite) {
  const validationSchema = yup.object().shape({
    langues: yup.string().required().label("La langue").nullable(),
    pays: yup.string().required().label("Le pays").nullable(),
    nom: yup.string().required().label("Le titre").nullable(),
    slug: yup.string().nullable(),
    logo: yup.mixed().when("slug", {
      is: (val: string) => !val,
      then: yup
        .mixed()
        .test("required", "Le logo est un champs obligatoire", (file) => {
          if (file) return true;
          return false;
        }),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm<EntiteFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditEntiteMutation();
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    let file: any = e?.target?.files?.length ? e?.target?.files[0] : "";
    if (file) {
      setLogo(URL.createObjectURL(file));
    } else {
      setLogo("");
    }
    setValue("logo", file || "");
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !item
          ? "Entité ajoutée avec succès!"
          : "Entité modifiée avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/admin/entites");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof EntiteFormData)[] = [
        "slug",
        "langues",
        "nom",
        "pays",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
      if (item?.logo) {
        setLogo(getFile(item?.logo));
      }
    }
  }, [item]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: EntiteFormData) => {
    // console.log("data", data);
    const fd = await new FormData();
    for (let key of Object.keys(data)) {
      const v = data[key];
      if (v instanceof FileList) {
        const file = v[0];
        if (file) {
          fd.append(key, file);
        }
      } else {
        fd.append(key, v);
      }
    }
    await sendData({ slug: item?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeFile,
    logo,
    watch,
  };
}

export default UseCrudEntiteForm;
export function UseDeleteEntite(slug: string) {
  const [deleteData] = useDeleteEntiteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette entité ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'entité a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
