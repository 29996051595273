import React, { useState } from "react";
import "./MessageAdmin.css";
import ListeMessageTable from "./MessageTables/ListeMessageTable";
import { MdOutlineAddBox } from "react-icons/md";
import AddNotificationModal from "./Modal/AddNotificationModal";
import { showModal } from "../../../utils/Utils";

function MessageAdmin() {
  const [query, setQuery] = useState("")
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin control-fer-page-title-container mb-0">
          <div className="custom-top-content pb-4">
            <div className="admin-page-title">Messages</div>
            <button
              className="custom-add-btn"
              onClick={() => showModal("addAnnonceModal")}
            >
              <span>
                <MdOutlineAddBox />
              </span>
              Nouvelle notification
            </button>
            <AddNotificationModal modalId="addAnnonceModal" />
          </div>
          <div className="custom-message-content pt-4">
            <div className="custom-filtered-container d-flex align-items-center gap-3">
              <span>Filter</span>
              <input
                type="search"
                name="search_admin"
                id="search_admin"
                placeholder="Rechercher"
                onChange={(e) => setQuery(e?.target?.value)}
              />
            </div>
            <div className="pt-4">
              <ListeMessageTable q={query} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageAdmin;
