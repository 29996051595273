import React, { useState } from "react";
import cover from "../../../../assets/appImages/admincover.png";
import { FiEdit } from "react-icons/fi";
import { useAppSelector } from "../../../../redux/hook";
import { getAvatar } from "../../../../utils/Utils";
import { Input } from "../../../common/Input";
import UseEditUserForm from "../request/UseEditUserForm";
import ChangePasswordModal from "../../../auth/ChangePasswordModal";

// export const Input = ({
//   label,
//   type,
//   placeholder,
//   onClick,
// }: {
//   label: string;
//   type?: string;
//   placeholder?: string;
//   onClick?: any;
// }) => {
//   return (
//     <>
//       <div className="label-and-input-content">
//         <label htmlFor="name">{label}</label>
//         <div className="custom-sm-input">
//           <input type={type} placeholder={placeholder} />
//           <span className="edit-btn" onClick={onClick}>
//             <FiEdit />
//           </span>
//         </div>
//       </div>
//     </>
//   );
// };

function MonCompteAdmin() {
  const { user } = useAppSelector((s) => s?.user);
  const { register, errors, onSubmit, isLoading } = UseEditUserForm();
  const [modalChangePassword, setModalChangePassword] = useState(false);

  function openModalChangePassword() {
    setModalChangePassword(true);
  }
  // console.log("user", user)
  return (
    <>
      <div className="admin-cover-and-name-container">
        <img
          src={getAvatar(user?.avatar)}
          alt="cover"
          srcSet=""
          loading="lazy"
        />
        <span>{user?.nom}</span>
      </div>
      <div className="custom-form-container">
        <div className="custom-title">Informations personnelles</div>
        <form onSubmit={onSubmit}>
          <div className="c_grid">
            <Input
              label="Prénom et Nom"
              type="text"
              required
              placeholder="Prénom et Nom"
              {...register("nom")}
              error={errors?.nom?.message}
            />
            <Input
              label="Adresse email"
              type="email"
              disabled
              required
              placeholder="Adresse email"
              {...register("email")}
              error={errors?.email?.message}
            />
            <Input
              label="Adresse"
              type="text"
              placeholder="Adresse"
              {...register("adresse")}
              error={errors?.adresse?.message}
            />

            <Input
              label="Numéro de téléphone"
              type="tel"
              placeholder="Numéro de téléphone"
              {...register("telephone")}
              error={errors?.telephone?.message}
            />
          </div>
          <div className="flex-r mt-5">
            <button
              className="btn auth-submit-btn trans-0-2 w-auto"
              type="submit"
              disabled={isLoading}
            >
              {!!isLoading && (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>En cours...</span>
                </>
              )}
              {!isLoading && "Enregistrer"}
            </button>
          </div>
        </form>
      </div>
      <div className="workcare-dash-admin-page-content-container mb-3">
        <div className="workcare-dash-admin-page-container">
          <div className="page-content-container mt-4">
            <div className="parent-table-container">
              <h5 className="workcare-dash-admin-titre-secondaire-deux">
                Mot de passe
              </h5>
              <div className="workcare-dash-admin-profile-section-password">
                <button
                  className="btn btn-change-password"
                  onClick={openModalChangePassword}
                >
                  Changer votre mot de passe
                </button>
              </div>
              <ChangePasswordModal
                modalChangePassword={modalChangePassword}
                setModalChangePassword={setModalChangePassword}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MonCompteAdmin;
