import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./UsersAdmin.css";
import { AiOutlinePlusCircle } from "react-icons/ai";
import UsersAdminTable from "./UsersAdminTable/UsersAdminTable";

function UsersAdmin() {
  const [query, setQuery] = useState("");
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin">
          <div className="page-title-container mb-3">
            <div className="admin-page-title">Utilisateurs</div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="content-form-filter mt-3 mb-4">
                <form>
                  <div className="content-form-search-bloc">
                    <input
                      type="search"
                      className="form-control form-control-search"
                      placeholder="Rechercher"
                      id="userSearch"
                      onChange={(e) => setQuery(e?.target?.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="admin-table">
            <UsersAdminTable q={query} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersAdmin;
