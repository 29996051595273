import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl, useLocationState } from "../../Utils";
import { Norme, NormeFormData } from "./normes.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const NormesApi = createApi({
  reducerPath: "normeApi",
  tagTypes: ["norme", "NormeList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getNormeList: builder.query<PaginationResults<Norme>, TypeQuery>({
      providesTags: ["NormeList"],
      query: (query) => QueryUrl("normes/", query),
    }),

    addOrEditNorme: builder.mutation<
      Norme,
      { slug?: string; data: NormeFormData | FormData }
    >({
      invalidatesTags: ["NormeList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `normes/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `normes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    normeById: builder.query<Norme | any, string>({
        query: (id) => `normes/${id}/`,
        providesTags: ["norme"],
      }),
    deleteNorme: builder.mutation<Norme, string>({
      query: (slug) => ({
        url: `normes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["NormeList"],
    }),
  }),
});

export const {
    useAddOrEditNormeMutation,
    useDeleteNormeMutation,
    useGetNormeListQuery,
    useLazyNormeByIdQuery
} = NormesApi;

export function useNormeFromLocation(): [
    Norme,
    boolean,
    string,
    (slug: string) => any
  ] {
    const itemState = useLocationState<Norme | undefined>(undefined);
    const [item, setItem] = useState(itemState);
    const { slug } = useParams<{ slug: string }>();
    const [findById, { data, isError, isLoading }] = useLazyNormeByIdQuery();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (slug) {
        findById(slug as string);
      }
    }, [slug]);
    useEffect(() => {
      if (isError && !itemState) {
        navigate(-1);
      }
    }, [isError]);
    useEffect(() => {
      if (data) {
        // console.log("data", data);
        setItem(data);
      }
    }, [data]);
  
    return [item as Norme, isLoading, slug as string, findById];
  }
