import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import { NavLink } from "react-router-dom";
import "./ParametreAdmin.css";
import MonCompteAdmin from "./ParametreTabs/MonCompteAdmin";
import ListeAdministrateurs from "./ParametreTabs/ListeAdministrateurs";
import MessagesContactAdmin from "./ParametreTabs/MessagesContactAdmin";
import { MdOutlineAddBox } from "react-icons/md";
import { useLocationState } from "../../../utils/Utils";
import { useAppSelector } from "../../../redux/hook";

const steps = [
  { id: "mon-compte", Component: MonCompteAdmin },
  { id: "liste-administrateurs", Component: ListeAdministrateurs },
  { id: "liste-administrateurs", Component: MessagesContactAdmin },
];

const tabs = [
  {
    key: "mon-compte",
    text: "Mon compte",
  },
  {
    key: "liste-administrateurs",
    text: "Administrateurs",
  },
  {
    key: "message-contact",
    text: "Messages de contact",
  },
];

function ParametreAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const item = useLocationState(undefined);

  useEffect(() => {
    if (item) {
      go(item);
    }
  }, [item]);

  const { user } = useAppSelector((s) => s?.user);
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="content-graph-admin control-fer-page-title-container mb-0">
          <div className="custom-top-content pb-4">
            <div className="admin-page-title">Paramètres</div>
            {tabs.map((tab, key) => {
              if (index === key && tab.text === "Administrateurs" && user?.user_type === "superadmin") {
                return (
                  <>
                    <NavLink
                      to="/admin/ajouter-administrateur"
                      className="btn-theme-body-page"
                    >
                      <span className="pe-2">
                        <MdOutlineAddBox />
                      </span>
                      <span>Ajouter un admin</span>
                    </NavLink>
                  </>
                );
              }
            })}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="medsain-dash-tabs mb-0">
                <ul className="tabs-container tabs-container-admin custom-small-menu-item mb-0 pt-4 pb-2 px-4">
                  {tabs.map((tab: any, key: any) => (
                    <li
                      className={`tab-item  ${
                        index === key && "tab-active-item-admin"
                      }`}
                      key={key}
                      onClick={() => go(key)}
                      role="button"
                    >
                      {tab.text}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="content-graph-admin medsain-page-title-container mb-2"></div>

              <div className="hippocrate-dash-admin-page-content-container mb-1">
                <div className="hippocrate-tabs-locataire-container">
                  <div className="tabs-component-container">
                    <Component />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParametreAdmin;
