import React, { useState } from "react";
import ListeAdminsTable from "../ParametreTables/ListeAdminsTable";

function ListeAdministrateurs() {
  const [query, setQuery] = useState("");
  return (
    <>
      <div className="custom-filtered-container d-flex align-items-center gap-3">
        <span>Filter</span>
        <input
          type="search"
          name="search_admin"
          id="search_admin"
          placeholder="Entrer un nom"
          onChange={(e) => setQuery(e?.target?.value)}
        />
      </div>
      <ListeAdminsTable q={query} />
    </>
  );
}

export default ListeAdministrateurs;
