import React, { Dispatch, SetStateAction } from 'react'
import Modal from "react-modal";
import CloseModalBtn from '../shared/CloseModalBtn';
import { InputPassword } from '../common/Input';
import { BtnSubmit } from '../common/Button';
import UseChangePasswordForm from './requestForm/UseChangePasswordForm';

 const customStyles = {
   content: {
     top: "50%",
     left: "50%",
     right: "auto",
     bottom: "auto",
     marginRight: "-50%",
     transform: "translate(-50%, -50%)",
     width: "40%",
     zIndex: 99999,
    //  height: "100vh",
   },
 };
function ChangePasswordModal({
  modalChangePassword,
  setModalChangePassword,
}: {
  modalChangePassword: boolean;
  setModalChangePassword: Dispatch<SetStateAction<boolean>>;
}) {
  function closeModalChangePassword() {
    setModalChangePassword(false);
  }
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    passwordHandleReset,
    PasswordInput,
  } = UseChangePasswordForm(closeModalChangePassword);
  return (
    <Modal
      isOpen={modalChangePassword}
      onRequestClose={() => {
        closeModalChangePassword();
        passwordHandleReset();
      }}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="modal-header flex-sb">
        <h5 className="modal-title-admin">Changer de mot de passe</h5>
        <CloseModalBtn
          onClick={() => {
            closeModalChangePassword();
            passwordHandleReset();
          }}
        />
      </div>

      <div className="pt-3">
        <form onSubmit={onSubmit}>
          <div className="row py-3 px-2">
            <div className="col-12 mb-3">
              <InputPassword
                label="Ancien mot de passe"
                id="password"
                placeholder="Ancien mot de passe"
                required
                {...register("old_password")}
                error={errors?.old_password?.message}
              />
            </div>
            <div className="col-12 mb-3">
              <InputPassword
                label="Nouveau mot de passe"
                id="newPassword"
                placeholder="Nouveau mot de passe"
                error={errors?.new_password?.message}
                onChange={(e) => passwordHandleChange(e)}
              />
            </div>
            <div className="col-12 mb-4">
              <InputPassword
                label="Confirmer le mot de passe"
                id="confirm_password"
                placeholder="Confirmer le mot de passe"
                required
                {...register("confirm_password")}
                error={errors?.confirm_password?.message}
              />
            </div>
            <div className="flex-r mb-3">
              <BtnSubmit
                label="Changer"
                isLoading={isLoading}
                style={{
                  width: "auto",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              />
            </div>
            {/* ============= Validation password ============= */}

            {PasswordInput && (
              <div className="col-md-12">
                <div className="input-group my-2 password-validation-terms-row">
                  <div className="password-validation-terms-container">
                    <h4 className="password-validation-terms-title">
                      Votre mot de passe doit contenir :{" "}
                    </h4>
                    <ul className="password-validation-terms-ul p-l-14">
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinCharacter && "text-success")
                        }
                      >
                        Au minimum 8 caractères
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinUppercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en majuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinLowercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en minuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinNumber && "text-success")
                        }
                      >
                        Au minimum 1 nombre
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinSpecialCharacter && "text-success")
                        }
                      >
                        Au minimum 1 caractère spéciale
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal