import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl, useLocationState } from "../../Utils";
import { IAdminData } from "./dashboard.type";

export const DashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["dashboard"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getAdminData: builder.query<IAdminData, TypeQuery>({
      query: (query) => QueryUrl("dashboard/", query),
      providesTags: ["dashboard"],
    }),
    getTopClient: builder.query<any, TypeQuery>({
      query: (query) => QueryUrl("top_clients/", query),
      providesTags: ["dashboard"],
    }),
  }),
});

export const { useGetAdminDataQuery, useGetTopClientQuery } = DashboardApi;
