import { useSonFromLocation } from "../../../utils/api/sons/sons.api";
import BreadCrumb from "../../shared/BreadCrumb";
import InfoPersoTab from "./DetailSonTabs/InfoPersoTab";

function DetailsSonAdmin() {
  const [item] = useSonFromLocation();

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-2">
          <BreadCrumb pageName="Langue" />
        </div>
        <div className="content-graph-admin control-fer-page-title-container mb-3 p-3">
          <div className="row">
            <div className="col-md-12">
              <div className="admin-cover-and-name-container my-3">
                <span>{item?.nom}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="content-graph-admin">
          <div className="tabs-component-container">
            <InfoPersoTab item={item} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsSonAdmin;
