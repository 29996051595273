import { BiCategory } from "react-icons/bi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaUser, FaUsers } from "react-icons/fa";
import { MdOutlineWork, MdSignLanguage } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../utils/Utils";
import VersionChecker from "../../../../versionChecker/VersionChecker";

function SidebarAdmin() {
  const { user } = useAppSelector((s) => s?.user);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
      <div className="hoverable mt-5" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <ul className="nav nav-items-custom flex-column">
            <li className="nav-item nav-item-vertical-custom">
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/tableau-de-bord"
                data-tooltip-content="Mon tableau de bord"
              >
                <span className="icon-container-nav-link-vertival">
                  <BiCategory />
                </span>
                <span className="hiddenable-navlink-label">
                  Mon tableau de bord
                </span>
              </NavLink>
            </li>
            {(is_SuperAdmin || user?.entites) && (
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                  to="/admin/entites"
                  data-tooltip-content="Entités"
                >
                  <span className="icon-container-nav-link-vertival">
                    <MdOutlineWork />
                  </span>
                  <span className="hiddenable-navlink-label">Entités</span>
                </NavLink>
              </li>
            )}
            {(is_SuperAdmin || user?.entites) && (
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                  to="/admin/langues"
                  data-tooltip-content="Langues"
                >
                  <span className="icon-container-nav-link-vertival">
                    <MdSignLanguage />
                  </span>
                  <span className="hiddenable-navlink-label">Langues</span>
                </NavLink>
              </li>
            )}
            {(is_SuperAdmin || user?.normes) && (
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                  to="/admin/normes"
                  data-tooltip-content="Entités"
                >
                  <span className="icon-container-nav-link-vertival">
                    <MdOutlineWork />
                  </span>
                  <span className="hiddenable-navlink-label">Normes</span>
                </NavLink>
              </li>
            )}
            {(is_SuperAdmin || user?.utilisateurs) && (
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                  to="/admin/utilisateurs"
                  data-tooltip-content="Utilisateurs"
                >
                  <span className="icon-container-nav-link-vertival">
                    <FaUsers />
                  </span>
                  <span className="hiddenable-navlink-label">Utilisateurs</span>
                </NavLink>
              </li>
            )}
            {(is_SuperAdmin || user?.annonces) && (
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                  to="/admin/annonces"
                  data-tooltip-content="Annonces"
                >
                  <span className="icon-container-nav-link-vertival">
                    <BsFillChatDotsFill />
                  </span>
                  <span className="hiddenable-navlink-label">Annonces</span>
                </NavLink>
              </li>
            )}
            {(is_SuperAdmin || user?.parametres) && (
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                  to="/admin/parametres"
                  data-tooltip-content="Paramètres"
                >
                  <span className="icon-container-nav-link-vertival">
                    <FaUser />
                  </span>
                  <span className="hiddenable-navlink-label">Paramètres</span>
                </NavLink>
              </li>
            )}
          </ul>
          <p className="text-version-footer-app">{"V" + VersionChecker()}</p>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
