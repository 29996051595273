import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineChart({ data }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
    },
  };

  const labels = data?.graph?.map((item) => item?.date);

  const datas = {
    labels,
    datasets: [
      {
        label: "Request",
        data: data?.graph?.map((item) => item?.requests_count),
        borderColor: "#00CCFF",
        backgroundColor: "#a8bfd51f",
        fill: true,
        lineTension: 0.3,
      },
    ],
  };

  return <Line options={options} data={datas} />;
}

export default LineChart;
