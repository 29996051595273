import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import InfoPersoTab from "./DetailEntiteTabs/InfoPersoTab";
import Historique from "./DetailEntiteTabs/Historique";
import profile from "../../../assets/appImages/profil.png";
import { useEntiteFromLocation } from "../../../utils/api/entites/entites.api";
import { getImage } from "../../../utils/Utils";
import BreadCrumb from "../../shared/BreadCrumb";

const steps = [
  { id: "info-perso", Component: InfoPersoTab },
  { id: "historique", Component: Historique },
];

const tabs = [
  {
    key: "info-perso",
    text: "Informations personnelles",
  },
  {
    key: "historique",
    text: "Historique",
  },
];

function DetailsEntiteAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const [item] = useEntiteFromLocation();
  const { Component } = step;

  const props = {
    item,
  };

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-2">
          <BreadCrumb pageName="Entités" />
        </div>
        <div className="content-graph-admin control-fer-page-title-container mb-3 p-3">
          <div className="row">
            <div className="col-md-12">
              <div className="admin-cover-and-name-container my-3">
                <img src={getImage(item?.logo)} alt="cover" srcSet="" className="logo-entity" />
                <span>{item?.nom}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="content-graph-admin">
          <div className="medsain-dash-tabs mb-4">
            <ul className="tabs-container tabs-container-admin custom-small-menu-item mb-0 pt-4 pb-2 px-4">
              {tabs.map((tab: any, key: any) => (
                <li
                  className={`tab-item  ${
                    index === key && "tab-active-item-admin"
                  }`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsEntiteAdmin;
