

export enum UserType {
  admin = "admin",
  superAdmin = 'superadmin'
}

export type CiviliteType = "homme" | "femme";

export interface IUser {
  id: string;
  slug: string;
  user_type: string;
  avatar: string;
  nom: string;
  adresse: string;
  telephone: string;
  password: string;
  confirmPassword: string;
  pays: string;
  condition: boolean;
  is_active: boolean;
  is_archive: boolean;
  longitude: string;
  latitude: string;
  email: string;
  compagnie: string;
  token: string;
  admin_type: string;
  dashboard: boolean;
  entites: boolean;
  utilisateurs: boolean;
  annonces: boolean;
  parametres: boolean;
  normes: boolean;
}

export type UserFormData = Partial<IUser>;

export interface AuthState {
  user: IUser | any;
  token?: string | null;
}

export type PaginationResults<T> = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
  limit?: number;
  nbPage?: number;
  nbPages?: number;
};

export type PaginationDatas<T> = {
  count?: number;
  data: T[];
};

export type TypeQuery = Partial<{
  page?: number;
  limit?: number;
  word?: string | null;
  slug?: string;
  name?: string | null;
  minPrice?: string | null;
  maxPrice?: string | null;
  category?: string | null;
  orderBy?: string | null;
  order?: "DESC" | "ASC";
  user?: string;
  product?: string;
  productType?: "pack" | "product";
  numOrder?: string;
  search?: string;
  user_type?: string;
  service?: string;
  type_de_produit?: string;
  categorie?: string;
  pays?: string;
  q?: string;
  status?: string;
  type?: string;
  source?: string;
  target?: string;
  entite?: string;
  filter?: string;
  date?: string | null;
}>;

export type AddressFormData = {
  id: string;
  prenom: string;
  nom: string;
  adresse: string;
  complementAdresse: string;
  quartier: string;
  ville: string;
  telephone1: string;
  telephone2: string;
  isDefault: boolean;
  latitude: number | string;
  longitude: number | string;
};
