import React, { useState } from "react";
import { useGetNormeListQuery } from "../../../../utils/api/normes/normes.api";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { Norme } from "../../../../utils/api/normes/normes.type";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/Pagination";
import { UseDeleteNorme } from "../request/UseCrudNormeForm";

function DeleteButton({ item }: { item: Norme }) {
  const onDelete = UseDeleteNorme(item?.slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

function NormesAdminTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetNormeListQuery({
    page,
    limit,
    q,
  });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <Link
              to={`/admin/norme/${row?.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              state={{ entite: row }}
            >
              <AiFillEye />
            </Link>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/modifier-norme/${row?.slug}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteButton item={row} />
          </div>
        </div>
      </>
    );
  };

  const countryFormatter = (cell: string, row: Norme) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <p className="name-profil-table mb-0">{cell}</p>
      </div>
    );
  };
  const columns = [
    {
      dataField: "pays",
      text: `Pays`,
      formatter: countryFormatter,
    },
    {
      dataField: "titre",
      text: "Titre",
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPage(page);
                setLimit(perPage);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default NormesAdminTable;
