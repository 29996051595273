import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import UseLoginForm from "./requestForm/UseLoginForm";
import { FormError } from "../common/Input";
import HeaderEmpty from "../shared/HeaderEmpty";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {register,errors,isLoading,onSubmit} = UseLoginForm()
  return (
    <div>
      <HeaderEmpty />
      <div className="container-page-login">
        <div className="auth-row row h-100">
          <div className="col-md-5 auth-col auth-left-side-col d-flex">
            <div className="auth-left-side-container"></div>
          </div>
          <div className="col-md-7 auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container">
                <h1 className="auth-form-title mb-4">Connexion</h1>
                <div className="content-form-login-page">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Email
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email-or-username"
                            placeholder="Email"
                            aria-label="Username"
                            {...register("email")}
                          />
                          <FormError error={errors?.email?.message} />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link
                          to={"/mot-de-passe-oublie"}
                          className="forget-password-link"
                        >
                          Mot de passe oublié ?
                        </Link>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Mot de passe
                          </label>
                          <div className="input-with-eye-container">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control auth-form-control"
                              id="password"
                              placeholder="Mot de passe"
                              aria-label="Password"
                              {...register("password")}
                            />
                            <span
                              className="show-hide-password"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <AiFillEyeInvisible />
                              ) : (
                                <AiFillEye />
                              )}
                            </span>
                          </div>
                          <FormError error={errors?.password?.message} />
                        </div>
                      </div>

                      <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5">
                        <button
                          className="btn auth-submit-btn trans-0-2"
                          type="submit"
                          disabled={isLoading}
                        >
                          {!!isLoading && (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Connexion...</span>
                            </>
                          )}
                          {!isLoading && "Connexion"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;