import React, { useState } from "react";
import "./DashboardAdmin.css";
import LineChart from "./LineChat/LineChart";
import Icon1 from "../../../assets/icons/requete.svg";
import Icon2 from "../../../assets/icons/users.svg";
import Icon3 from "../../../assets/icons/icon3.png";
import TopClientTable from "./TopClientTable/TopClientTable";
import {
  useGetAdminDataQuery,
  useGetTopClientQuery,
} from "../../../utils/api/dashboard/dashboard.api";
import { useGetEntiteListQuery } from "../../../utils/api/entites/entites.api";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppSelector } from "../../../redux/hook";

const DashboardAdmin = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filter, setFilter] = useState<"week" | "month" | "year">("week");
  const [selectedEntite, setSelectedEntite] = useState("");
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: datas = { results: [], count: 0 } } = useGetEntiteListQuery({});

  const { data, isLoading } = useGetAdminDataQuery({
    date: moment(selectedDate).format("DD-MM-YYYY"),
    filter: filter,
    entite: user?.user_type !== "superadmin" ? user?.pays : selectedEntite,
  });

  const { data: TopClients = { results: [], count: 0 }, isLoading: load } =
    useGetTopClientQuery({
      entite: user?.user_type !== "superadmin" ? user?.pays : selectedEntite,
      page,
      limit,
    });

  const handleEntiteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEntite(event.target.value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value as "week" | "month" | "year");
  };

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row pb-4">
          <div className="col-lg-3 col-md-6">
            <div className="content-select-date-graph">
              {user?.user_type !== "superadmin" ? (
                <select
                  className="form-control form-control-select-dashboard"
                  onChange={handleEntiteChange}
                  value={selectedEntite}
                  disabled
                >
                  <option value={user?.pays} disabled selected>
                    {user?.pays}
                  </option>
                </select>
              ) : (
                <select
                  className="form-control form-control-select-dashboard"
                  onChange={handleEntiteChange}
                  value={selectedEntite}
                >
                  {datas?.results?.map((entite) => (
                    <option key={entite?.slug} value={entite?.pays}>
                      {entite?.pays}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-4 col-md-12 mb-3 dis-flex">
            <div className="item-stat-dashboad-view ">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="content-item-stat-dashboad-view mt-2">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Requêtes
                    </p>
                  </div>
                  <div className="icon-item-stat-dashboad-view">
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {data?.requests}
                    </p>
                    <p className="text-describe-dash-view">
                      {data?.requests_change_percentage} depuis le mois dernier
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <img
                    src={Icon1}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="content-item-stat-dashboad-view mt-2">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      UTILISATEURS
                    </p>
                  </div>
                  <div className="icon-item-stat-dashboad-view">
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {data?.users}
                    </p>
                    <p className="text-describe-dash-view">
                      {" "}
                      <span style={{ color: "#EB5757" }}>
                        {data?.users_change_percentage}
                      </span>{" "}
                      depuis le mois dernier
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <img
                    src={Icon2}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
             
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                <div className="content-item-stat-dashboad-view mt-2">
                <p className="titre-item-stat-dashboad-view mb-1">
                CLIENTS
                </p>
              </div>
                  <div className="icon-item-stat-dashboad-view">
                    <p className="chiffre-item-stat-dashboad-view mb-1">46</p>
                    <p className="text-describe-dash-view">+1.70% depuis le mois dernier</p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <img
                    src={Icon3}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="content-graph-admin mt-5">
          <div className="chart-item-container">
            <div className="content-chart-title mb-4">
              <h2 className="chart-item-admin-title mb-0">
                Progression des commandes
              </h2>
              <div className="content-select-filter-graph">
                <select
                  className="form-control form-control-select-date-graph"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <option value="week">Semaine</option>
                  <option value="month">Mois</option>
                  <option value="year">Année</option>
                </select>
              </div>
              <div className="content-select-date-graph">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date: Date) => setSelectedDate(date)}
                  className="chart-item-admin-week-indicator"
                  showMonthYearPicker={filter === "month"}
                  showYearPicker={filter === "year"}
                  dateFormat={
                    filter === "month"
                      ? "MMMM/yyyy"
                      : filter === "year"
                      ? "yyyy"
                      : "dd/MM/yyyy"
                  }
                />
              </div>
            </div>
            <LineChart data={data} />
          </div>
        </div>
        <div className="content-graph-admin mt-3">
          <div className="content-graph-admin page-title-container mb-2">
            <div className="admin-page-title">Top clients</div>
          </div>
          <TopClientTable
            clients={TopClients}
            isLoading={load}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
