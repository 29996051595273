import React, { useState } from "react";
import { Input } from "../../common/Input";
import { useAdminFromLocation } from "../../../utils/api/user/user.api";
import UseCrudAdminForm from "./request/UseCrudAdminForm";
import BreadCrumb from "../../shared/BreadCrumb";
import { useGetEntiteListQuery } from "../../../utils/api/entites/entites.api";
import { Authorisations } from "../../../utils/constante";

function AddOrUpdateSousAdmin() {
  const [item] = useAdminFromLocation();
  const { data: datas = { results: [], count: 0 } } =
    useGetEntiteListQuery<any>({});
  const { register, errors, onSubmit, isLoading, show, handleAdminChange } =
    UseCrudAdminForm(item);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb pageName="Paramètres" />
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title">
            {!item ? "Ajouter" : "Modifier"} un admin
          </div>
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-6">
                  <Input
                    required
                    type="text"
                    label="Nom de l’administrateur"
                    id="nom"
                    placeholder="Nom de l’administrateur"
                    {...register("nom")}
                    error={errors?.nom?.message}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    required
                    type="email"
                    label="Adresse mail"
                    id="email"
                    placeholder="Adresse mail"
                    {...register("email")}
                    error={errors?.email?.message}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    required
                    type="tel"
                    label="Téléphone"
                    id="phone"
                    placeholder="Téléphone"
                    {...register("telephone")}
                    error={errors?.telephone?.message}
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="pays"
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby="pays"
                  >
                    Pays
                  </label>
                  <select
                    className="form-select form-select-modal-custom mb-2"
                    id="pays"
                    {...register("pays")}
                  >
                    {datas?.results?.map((entite) => (
                      <option key={entite?.slug} value={entite?.pays}>
                        {entite?.pays}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="pays"
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby="pays"
                  >
                    Type d'admin
                  </label>
                  <select
                    className="form-select form-select-modal-custom mb-2"
                    id="pays"
                    {...register("user_type")}
                    onChange={(e) => handleAdminChange(e)}
                  >
                    <option value="superadmin">Super admin</option>
                    <option value="admin">Sous admin</option>
                  </select>
                </div>
                {show ? (
                  <>
                    <div className="custom-wrapper-title py-5">
                      Habilitations
                    </div>
                    <div className="container-checkbox-input">
                      <div className="form-checkbox-input checkbox-input">
                        <div className="custom-permission-container">
                          {Authorisations?.map((item) => (
                            <label key={item?.field}>
                              <input
                                type="checkbox"
                                {...register(item?.field)}
                                id={`checkbox-${item?.field}`}
                                disabled={item?.field === `${"dashboard"}`}
                              />
                              <span>{item?.label}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn auth-submit-btn trans-0-2 w-auto"
                    type="submit"
                    disabled={isLoading}
                  >
                    {!!isLoading && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>En cours...</span>
                      </>
                    )}
                    {!isLoading && "Enregistrer"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateSousAdmin;
