import React, { useState } from "react";
import "./Login.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { UseResetPasswordForm } from "./requestForm/UseResetPasswordForm";
import ErrorMessage from "../common/ErrorMessage";
import { BtnSubmit } from "../common/Button";
import HeaderEmpty from "../shared/HeaderEmpty";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    password,
  } = UseResetPasswordForm();
  return (
    <div>
      <HeaderEmpty />
      <div className="container-page-login">
        <div className="auth-row row h-100">
          <div className="col-md-5 auth-col auth-left-side-col d-flex">
            <div className="auth-left-side-container"></div>
          </div>
          <div className="col-md-7 auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container">
                <h1 className="auth-form-title mb-4">Modifier mot de passe</h1>
                <div className="content-form-login-page">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      {/* <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <label className="form-label form-label-auth-login">
                          Adresse email
                        </label>
                        <input
                          type={`email`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          aria-label="Username"
                          placeholder="Email"
                        />
                      </div>
                    </div> */}
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Code
                          </label>
                          <input
                            type={`number`}
                            min={0}
                            className="form-control auth-form-control"
                            id="code-or-username"
                            aria-label="Username"
                            placeholder="Code"
                            {...register("code")}
                          />
                          {errors?.code && (
                            <ErrorMessage message={errors?.code?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Nouveau mot de passe
                          </label>
                          <div className="input-with-eye-container">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control auth-form-control"
                              id="new_password"
                              placeholder="Mot de passe"
                              aria-label="Password"
                              aria-placeholder="***********"
                              onChange={passwordHandleChange}
                            />
                            <span
                              className="show-hide-password"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <AiFillEyeInvisible />
                              ) : (
                                <AiFillEye />
                              )}
                            </span>
                          </div>
                        </div>

                        {errors?.new_password && (
                          <ErrorMessage
                            message={errors?.new_password?.message}
                          />
                        )}
                      </div>
                      {/* ============= Validation password ============= */}

                      {password && (
                        <div className="col-md-12">
                          <div className="input-group my-2 password-validation-terms-row">
                            <div className="password-validation-terms-container">
                              <h4 className="password-validation-terms-title">
                                Votre mot de passe doit contenir :{" "}
                              </h4>
                              <ul className="password-validation-terms-ul p-l-14">
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinCharacter && "text-success")
                                  }
                                >
                                  Au minimum 8 caractères
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinUppercase && "text-success")
                                  }
                                >
                                  Au minimum 1 caractère en majuscule
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinLowercase && "text-success")
                                  }
                                >
                                  Au minimum 1 caractère en minuscule
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinNumber && "text-success")
                                  }
                                >
                                  Au minimum 1 nombre
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinSpecialCharacter && "text-success")
                                  }
                                >
                                  Au minimum 1 caractère spéciale
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Confirmer le mot de passe
                          </label>
                          <div className="input-with-eye-container">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control auth-form-control"
                              id="new_password_confirm"
                              placeholder="Confirmer le mot de passe"
                              aria-label="Password"
                              aria-placeholder="***********"
                              {...register("new_password_confirm")}
                            />
                            <span
                              className="show-hide-password"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <AiFillEyeInvisible />
                              ) : (
                                <AiFillEye />
                              )}
                            </span>
                          </div>
                        </div>
                        {errors?.new_password_confirm && (
                          <ErrorMessage
                            message={errors?.new_password_confirm?.message}
                          />
                        )}
                      </div>
                      <div className="col-md-6 offset-md-3 auth-submit-btn-container mt-5">
                        <BtnSubmit label="Envoyer" isLoading={isLoading} />
                      </div>
                      <div className="col-md-6 offset-md-3 mt-3">
                        <NavLink
                          className="btn forget-password-link w-100"
                          to="/"
                        >
                          Se connecter
                        </NavLink>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
