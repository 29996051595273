import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import "../../MessageAdmin/MessageAdmin.css";
import { useGetAnnonceListQuery } from "../../../../utils/api/annonce/annonce.api";
import { CustomPagination } from "../../../common/Pagination";
import { createMarkup, showModal } from "../../../../utils/Utils";
import { Annonce } from "../../../../utils/api/annonce/annonce.type";
import AddNotificationModal from "../Modal/AddNotificationModal";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { UseDeleteAnnonce } from "../request/UseCrudAnnonceForm";

function DeleteButton({ item }: { item: Annonce }) {
  const onDelete = UseDeleteAnnonce(item?.slug);

  return (
    <button
      className="btn btn-action-icon-delete with-tooltip"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}
function ListeMessageTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetAnnonceListQuery({ page, limit,q });
  
    const showMessage = (annonce:Annonce) => {
      Swal.fire({
        title: annonce?.titre,
        html: annonce?.message,
        width: 700,
        confirmButtonColor: Color.themeColor,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Fermer",
        focusCancel: false,
        customClass: {
          htmlContainer: "annonce-html-container",
          title: "annonce-title-swal",
        },
      });

    }

  const actionFormatter = (cell: string, row: Annonce) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex justify-content-center gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              onClick={() => showMessage(row)}
            >
              <AiFillEye />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              onClick={() => showModal(`editAnnonceModal${cell}`)}
            >
              <MdEdit />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteButton item={row} />
          </div>
        </div>
        <AddNotificationModal modalId={`editAnnonceModal${cell}`} item={row} />
      </>
    );
  };

  const messageFormatter = (cell: string) => {
    return (
      <>
        <div
          className=""
          dangerouslySetInnerHTML={createMarkup(cell, true, 100)}
        />
      </>
    );
  };

  const columns = [
    {
      dataField: "titre",
      text: `Titre`,
    },
    {
      dataField: "message",
      text: "Message",
      formatter: (cell: string) => messageFormatter(cell),
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  // const selectRow = {
  //   mode: "checkbox",
  // };

  return (
    <>
      {!!isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            // selectRow={selectRow}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPage(page);
                setLimit(perPage);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ListeMessageTable;
