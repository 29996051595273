import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppRoutes } from "./routes/router";
import { useMeAdminQuery } from "./utils/api/user/user.api";
import { useAppDispatch, useAppSelector } from "./redux/hook";
import { onSetUser } from "./redux/slice/User.slice";
import { isSuperAdmin } from "./utils/Utils";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

function FetchUser() {
  const { isSuccess, data } = useMeAdminQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess) {
      // console.log("data", data);
      dispatch(onSetUser(data));
    }
  }, [isSuccess, data]);

  return null;
}

function App() {
  const isConnected = useAppSelector((s) => !!s.user?.token);
  const { user } = useAppSelector((s) => s?.user);
  const routes = useRoutes(AppRoutes);

  return (
    <div className="app-routing-container">
      <ScrollToTop />
      {isConnected && !isSuperAdmin(user) && <FetchUser />}
      {routes}
    </div>
  );
}

export default App;
