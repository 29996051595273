import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { ChangePasswordData } from "../auth/auth.type";
import {
  AuthState,
  IUser,
  PaginationResults,
  TypeQuery,
  UserFormData,
} from "./user.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["user", "userList", "userById", "adminsList", "adminById"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<IUser, void>({
      providesTags: ["user"],
      // transformResponse: ({ data }) => data,
      query: () => {
        return "auth/me/";
      },
    }),
    meAdmin: builder.query<IUser, void>({
      providesTags: ["user"],
      // transformResponse: ({ data }) => data,
      query: () => {
        return "auth/me-admin/";
      },
    }),
    addOrEditUser: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "userList", "adminById"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `users/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditAdmin: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["adminsList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `user/admins/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/admins/`,
          method: "POST",
          body: data,
        };
      },
    }),
    changePassword: builder.mutation<any, ChangePasswordData>({
      query: (data) => ({
        url: `auth/change-password/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    getListUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["userList"],
      query: (query) => QueryUrl("utilisateurs", query),
    }),
    getListAdminUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["adminsList"],
      query: (query) => QueryUrl("user/admins", query),
    }),
    userById: builder.query<IUser | any, string>({
      query: (id) => `users/${id}/`,
      providesTags: ["userById"],
    }),
    adminBySlug: builder.query<IUser | any, string>({
      query: (slug) => `user/admins/${slug}/`,
      providesTags: ["adminById"],
    }),
    deleteUser: builder.mutation<IUser, string>({
      query: (slug) => ({
        url: `utilisateurs/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["userList", "adminsList"],
    }),
  }),
});

export const {
  useMeQuery,
  useAddOrEditUserMutation,
  useChangePasswordMutation,
  useGetListUserQuery,
  useAddOrEditAdminMutation,
  useGetListAdminUserQuery,
  useLazyUserByIdQuery,
  useDeleteUserMutation,
  useLazyAdminBySlugQuery,
  useMeAdminQuery
} = UserApi;
export function useAdminFromLocation(): [
  IUser,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyAdminBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findBySlug];
}