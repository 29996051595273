import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { cleannerError } from "../../../../utils/Utils";
import { EntiteFormData } from "../../../../utils/api/entites/entites.type";
import {
  useAddOrEditSonMutation,
  useDeleteSonMutation,
} from "../../../../utils/api/sons/sons.api";
import { Son } from "../../../../utils/api/sons/sont.type";
import { Color } from "../../../../utils/theme";

function validateAudio(Yup: typeof yup) {
  return Yup.mixed().test(
    "required",
    "L'audio n'est pas valide",
    (files?: FileList) => {
      if (!files || !(files instanceof FileList)) return true;
      const file = files[0];
      if (!file) return true;

      const fileName = file.name;

      // Get the file extension
      const fileExtension = fileName.split(".")?.pop()?.toLowerCase();

      // Define an array of allowed audio file extensions
      const allowedAudioExtensions = ["mp3", "wav", "ogg", "aac", "flac"];

      // Check if the file extension is in the allowed list
      return allowedAudioExtensions.includes(fileExtension ?? "");
    }
  );
}

function UseCrudSonsForm(item?: Son) {
  const validationSchema = yup.object().shape({
    pays: yup.string().required().label("Le pays").nullable(),
    nom: yup.string().required().label("Le titre").nullable(),
    longeur_du_fer: validateAudio(yup),
    poids_du_fer: validateAudio(yup),
    diametre_du_fer: validateAudio(yup),
    entrez_num_telephone: validateAudio(yup),
    fer_valide: validateAudio(yup),
    fer_non_valide: validateAudio(yup),
    choisir_une_langue: validateAudio(yup),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm<EntiteFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditSonMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !item
          ? "Langue ajoutée avec succès!"
          : "Langue modifiée avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/admin/langues");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof EntiteFormData)[] = ["nom", "pays"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
    }
  }, [item]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: EntiteFormData) => {
    // console.log("data", data);
    const fd = await new FormData();
    for (let key of Object.keys(data)) {
      const v = data[key];
      if (v instanceof FileList) {
        const file = v[0];
        if (file) {
          fd.append(key, file);
        }
      } else {
        fd.append(key, v);
      }
    }
    await sendData({ slug: item?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    watch,
  };
}

export default UseCrudSonsForm;

export function UseDeleteSon(slug: string) {
  const [deleteData] = useDeleteSonMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette langue ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Langue a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
