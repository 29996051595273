import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { BtnAnnulerModal, BtnSubmit } from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import { Input } from "../../../common/Input";
import { Annonce } from "../../../../utils/api/annonce/annonce.type";
import UseCrudAnnonceForm from "../request/UseCrudAnnonceForm";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import Editor from "../../../common/Editor";

function AddNotificationModal({
  modalId,
  item,
}: {
  modalId: string;
  item?: Annonce;
}) {
  const {
    register,
    message,
    handleChange,
    errors,
    onSubmit,
    isLoading,
    handleReset,
  } = UseCrudAnnonceForm(modalId, item);

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title" id="addNotificationModal">
              {!item ? `Ajouter une notification` : `Modifier une notification`}
            </h5>
            <div className="control-fer-btn-close-modal-container">
              <CloseModalBtn onClick={() => handleReset()} />
            </div>
          </div>
          <div className="modal-body">
            <div className="container-form">
              <form onSubmit={onSubmit}>
                <div className="row row-form-modal py-4">
                  <div className="col-md-12 mb-3">
                    <Input
                      type="text"
                      label="Titre"
                      id="titre"
                      placeholder="Titre"
                      {...register("titre")}
                      error={errors?.titre?.message}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor={"message"}
                        className={`form-label form-label-modal-custom`}
                        aria-labelledby={"message"}
                      >
                        Message
                      </label>
                      <Editor
                        className="editor-react-quill annonce-quill"
                        value={message}
                        onChange={(val) => handleChange(val)}
                      />
                      <ErrorMessage message={errors?.message?.message} />
                    </div>
                  </div>
                </div>

                <div className="row row-content-btn-action pt-3">
                  <div className="col-md-12 modal-add-btn-action-container">
                    <BtnAnnulerModal
                      label="Annuler"
                      onClick={() => handleReset()}
                    />

                    <BtnSubmit
                      isLoading={isLoading}
                      label={item ? "Modifier" : "Ajouter"}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNotificationModal;
