import { ReactElement } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import AddOrUpdateEntiteAdmin from "../components/admin/EntitesAdmin/AddOrUpdateEntiteAdmin";
import EntitesAdmin from "../components/admin/EntitesAdmin/EntitesAdmin";
import MessageAdmin from "../components/admin/MessageAdmin/MessageAdmin";
import AddOrUpdateSousAdmin from "../components/admin/ParametreAdmin/AddOrUpdateSousAdmin";
import DetailsAdmin from "../components/admin/ParametreAdmin/DetailsAdmin";
import ParametreAdmin from "../components/admin/ParametreAdmin/ParametreAdmin";
import UsersAdmin from "../components/admin/UsersAdmin/UsersAdmin";
import ForgetPassword from "../components/auth/ForgetPassword";
import Login from "../components/auth/Login";
import ResetPassword from "../components/auth/ResetPassword";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import PagesError404 from "../components/shared/PagesError/PagesError404";
import { useAppSelector } from "../redux/hook";
import { isAdmin, isSuperAdmin } from "../utils/Utils";

import DetailsEntiteAdmin from "../components/admin/EntitesAdmin/DetailsEntiteAdmin";
import AddOrUpdateNormeAdmin from "../components/admin/NormesAdmin/AddOrUpdateNormeAdmin";
import DetailsNormesAdmin from "../components/admin/NormesAdmin/DetailsNormesAdmin";
import NormesAdmin from "../components/admin/NormesAdmin/NormesAdmin";
import AddOrUpdateSonAdmin from "../components/admin/SonsAdmin/AddOrUpdateSonAdmin";
import DetailsSonAdmin from "../components/admin/SonsAdmin/DetailsSonAdmin";
import SonsAdmin from "../components/admin/SonsAdmin/SonsAdmin";
import Privacy from "../components/auth/Privacy";
import PrivacyMobile from "../components/privacy-mobile";
import { UserType } from "../utils/api/user/user.type";
import { FieldType } from "../utils/constante";
// UserRoutes
const IsAdminAllow = ({
  children,
  field,
}: {
  children: ReactElement;
  field: FieldType;
}) => {
  const { user } = useAppSelector((state) => state.user);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const navigate = useNavigate();
  if (!user[field] && !is_SuperAdmin) {
    setTimeout(() => {
      navigate(-1);
    }, 10);
  }
  return children;
};

export const ProtectedRoutes = ({
  children,
  userType,
}: {
  children: ReactElement;
  userType: UserType[];
}) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  const isAccess = userType?.includes(user?.user_type);
  const auth = user?.id;
  return isAccess && isAccess !== undefined ? (
    children
  ) : auth && auth !== undefined ? (
    <Navigate to="/" replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path: string = "/";
  if (isAdmin(user)) {
    path = "/admin/tableau-de-bord";
  }

  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

export const AppRoutes = [
  {
    path: "/",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/mobile-privacy",
    element: <PrivacyMobile />,
  },
  {
    path: "/mot-de-passe-oublie",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/reinitialiser-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ResetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "*",
    element: <PagesError404 />,
  },
  {
    path: "admin/",
    element: (
      <ProtectedRoutes userType={[UserType.admin, UserType.superAdmin]}>
        <AdminLayout />
      </ProtectedRoutes>
    ),
    children: [
      {
        element: <Navigate to="/admin/tableau-de-bord" replace />,
        index: true,
      },
      {
        path: "tableau-de-bord",
        element: <DashboardAdmin />,
      },
      {
        path: "utilisateurs",
        element: (
          <IsAdminAllow field="utilisateurs">
            <UsersAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "entites",
        element: (
          <IsAdminAllow field="entites">
            <EntitesAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "entite/:slug",
        element: (
          <IsAdminAllow field="entites">
            <DetailsEntiteAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-entite",
        element: (
          <IsAdminAllow field="entites">
            <AddOrUpdateEntiteAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-entite/:slug",
        element: (
          <IsAdminAllow field="entites">
            <AddOrUpdateEntiteAdmin />
          </IsAdminAllow>
        ),
      },

      {
        path: "langues",
        element: (
          <IsAdminAllow field="entites">
            <SonsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "langues/:slug",
        element: (
          <IsAdminAllow field="entites">
            <DetailsSonAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-langues",
        element: (
          <IsAdminAllow field="entites">
            <AddOrUpdateSonAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-langues/:slug",
        element: (
          <IsAdminAllow field="entites">
            <AddOrUpdateSonAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "normes",
        element: (
          <IsAdminAllow field="normes">
            <NormesAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-norme",
        element: (
          <IsAdminAllow field="normes">
            <AddOrUpdateNormeAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-norme/:slug",
        element: (
          <IsAdminAllow field="normes">
            <AddOrUpdateNormeAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "norme/:slug",
        element: (
          <IsAdminAllow field="normes">
            <DetailsNormesAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "parametres",
        element: (
          <IsAdminAllow field="parametres">
            <ParametreAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "annonces",
        element: (
          <IsAdminAllow field="annonces">
            <MessageAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-administrateur",
        element: (
          <IsAdminAllow field="parametres">
            <AddOrUpdateSousAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-administrateur/:slug",
        element: (
          <IsAdminAllow field="parametres">
            <AddOrUpdateSousAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "administrateur/:slug",
        element: (
          <IsAdminAllow field="parametres">
            <DetailsAdmin />
          </IsAdminAllow>
        ),
      },
    ],
  },
];
